/* =============================================================
   Tables
   ============================================================= */

.table__wrapper {
  position: relative;
}

.table__loading {
  position: absolute;
  top: 10rem;
  left: 0;
  right: 0;
  display: flex;
  align-items: center;
  justify-content: center;
  flex-direction: column;
  @include transition();

  span {
    padding-top: 1.5rem;
  }

  &.-relations {
    top: 4rem;
  }
}

.table__refresh {
  position: absolute;
  top: 4rem;
  left: 0;
  right: 0;
  display: flex;
  align-items: center;
  justify-content: center;
  flex-direction: row;
  @include transition();
}

table,
.table {
  width: 100%;
  max-width: 100%;
  background-color: transparent;
  font-size: $font-size-small;
  text-align: center;
}

tr,
.tr {
  &.-even {
    background-color: $purple-light;
  }

  &.-inactive {
    opacity: 0.5;
  }

  &.-refund.-odd {
    // background-color: lighten($color-error-bg, 5%);
    background-color: $color-error-bg;
  }

  &.-refund.-even {
    background-color: $color-error-bg;
  }
}

tr.-dragging,
.tr.-dragging {
  color: $purple-light;
  background-color: $purple;
  display: table;

  td,
  .td {
    a,
    button {
      color: $purple-light;
    }
  }
}

// .tr.tr--parent.-dragging {
//   .tr--child.-dragging {
//     display: none !important;
//   }
// }

thead tr:last-child th,
.thead .tr:last-child {
  border-bottom: 1px solid $purple-light;
}

th,
td,
.th,
.td {
  max-width: 400px;
  padding: 0.8rem 0.4rem;

  &:empty:before,
  span:not(.checkbox__custom):empty:before {
    content: '--';
  }

  span.mobile-label {
    display: none;
  }

  &.-tooltip {
    position: relative;
  }

  &:first-child {
    padding-left: 1rem;
  }

  &:first-child,
  &.-align-left {
    text-align: left;
  }

  &.-align-right {
    text-align: right;
  }

  &.-bold {
    font-weight: bold;
  }

  &.-upper {
    text-transform: uppercase;
  }

  &.-alert,
  &.-error {
    color: $color-error;
  }

  span.-alert,
  span.-error {
    color: $color-error;
    font-weight: 300;
  }

  &.-ellipsis {
    max-width: 240px;
    @extend %ellipsis;
  }

  &.gripper {
    padding: 0.6rem 1rem 0;
  }

  .-open,
  .-authorized {
    color: $color-body-2nd;
  }

  .-held {
    color: $color-link;
  }

  // .-closed,
  // .-paid {
  //   color: $color-success;
  // }

  .-void,
  .-refunded {
    color: $color-error;
  }

  .download-icon {
    position: relative;
    top: 0.2rem;
    padding: 0 0.5rem 0 0;
  }

  .download-link {
    display: block;
  }

  .download-qrcode {
    display: block;

    img {
      width: 12rem;
      @include breakpoint(mobile) {
        width: 24rem;
      }
    }
  }
}

th,
.th {
  padding: 0 0.4rem 0.8rem;
  @extend %header-text;
  line-height: 1.1;
  vertical-align: bottom;

  &:first-child {
    padding-left: 1rem;
  }

  .btn-link {
    @extend %header-text;
    font-weight: bold;
    color: $color-link;
    &:hover,
    &:active,
    &:focus {
      color: $color-link-hover;
    }
  }
}

.table-tooltip {
  cursor: pointer;
  color: $color-link;
  &:hover,
  &:active,
  &:focus {
    color: $color-link-hover;
  }
}

.table-tooltip__content {
  position: absolute;
  z-index: 3;
  display: block;
  width: 40rem;
  right: 0;
  bottom: 120%;
  @extend %overlay;
  font-size: 1.2rem;
  line-height: 1.4;
  text-align: center;
  @include transition();
  visibility: hidden;
  opacity: 0;
  @include translate3d(0, 1rem, 0);
  @include breakpoint(mobile) {
    left: 0;
    width: auto;
  }
}

.table-tooltip__trigger:hover ~ .table-tooltip__content {
  visibility: visible;
  opacity: 1;
  @include translate3d(0, 0, 0);
}

.table__results {
  display: flex;
  justify-content: space-between;
  align-items: center;
  // padding-left: 1rem;
  margin: 0 0 2.5rem 0;
  @include breakpoint(mobile_both) {
    flex-direction: column;
    justify-content: flex-start;
    align-items: flex-start;
  }

  p {
    display: inline-block;
    // color: $gray-light;
    font-size: $font-size-small;
    @include breakpoint(mobile_both) {
      display: inline;
    }
  }
}

.report__dates + .table__results {
  margin-top: 2.5rem;
}

.table__results__next {
  @include breakpoint(mobile_both) {
    display: block;
    width: 100%;
    margin-top: 0.5rem;
  }
}

.table__results__buttons {
  @include breakpoint(mobile_both) {
    margin-top: 1.5rem;
  }

  .btn:not(:first-child) {
    margin-left: 1rem;
  }
}

.table__results__refresh {
  display: inline-block;
  position: relative;
  top: 0.4rem;
  padding: 0 1rem 0 0.3rem;
  color: $purple;
}

.thead,
.tbody {
  width: 100%;
}

.tr {
  // @include clearfix;
  width: 100%;
  text-align: left;
}

.th,
.td {
  display: inline-block;
  vertical-align: middle;
  text-align: center;

  &:first-child {
    text-align: left;
  }
}

.tr--child {
  // .td {
  //   padding-top: 0;
  // }
  .td:first-child {
    position: relative;
    padding-left: 2.5rem;
    &:before {
      position: relative;
      content: '\2014';
      color: $color-link;
      padding: 0 0.5rem 0 0;
      top: -0.1rem;
    }
  }
}

.table__swatch {
  display: block;
  width: 10rem;
  margin: 0 auto;
}

.table--menu-categories {
  .th,
  .td {
    &:nth-child(1) {
      width: 40%;
      @include breakpoint(mobile) {
        width: 40%;
      }
    }
    &:nth-child(2) {
      width: 15%;
      @include breakpoint(mobile) {
        width: 25%;
      }
    }
    &:nth-child(3) {
      width: 15%;
      @include breakpoint(mobile) {
        width: 25%;
      }
    }
    &:nth-child(4) {
      width: 10%;
      @include breakpoint(mobile) {
        display: none;
      }
    }
    &:nth-child(5) {
      width: 10%;
      @include breakpoint(mobile) {
        display: none;
      }
    }
    &:nth-child(6) {
      width: 10%;
      @include breakpoint(mobile) {
        width: 10%;
      }
    }
  }
}

.table--rc-menus {
  .th,
  .td {
    &:nth-child(1) {
      width: 40%;
    }
    &:nth-child(2),
    &:nth-child(3),
    &:nth-child(4),
    &:nth-child(5) {
      width: 15%;
    }
  }
}

.table--categories {
  .th,
  .td {
    &:nth-child(1) {
      width: 15%;
      @include breakpoint(mobile_both) {
        width: 100%;
      }
    }
    &:nth-child(2) {
      width: 15%;
    }
    &:nth-child(3) {
      width: 12.5%;
    }
    &:nth-child(4) {
      width: 7.5%;
    }
    &:nth-child(5) {
      width: 15%;
    }
    &:nth-child(6) {
      width: 10%;
    }
    &:nth-child(7),
    &:nth-child(8),
    &:nth-child(9),
    &:nth-child(10),
    &:nth-child(11) {
      width: 5%;
    }

    .table__swatch {
      width: 100%;
      max-width: 5rem;
    }
  }
}

.table--items {
  th,
  td {
    &:nth-child(1) {
      width: 20%;
      @include breakpoint(mobile_both) {
        width: 100%;
      }
    }
    &:nth-child(2) {
      width: 17.5%;
    }
    &:nth-child(3) {
      width: 5%;
    }
    &:nth-child(4) {
      width: 17.5%;
    }
    &:nth-child(5) {
      width: 15%;
    }
    &:nth-child(6) {
      width: 15%;
    }
    &:nth-child(7),
    &:nth-child(8) {
      width: 5%;
    }
  }
}

.table--modifier-groups {
  th,
  td {
    &:nth-child(1) {
      width: 22.5%;
      @include breakpoint(mobile_both) {
        width: 100%;
      }
    }

    &:nth-child(2) {
      width: 22.5%;
    }

    &:nth-child(3) {
      width: 10%;
    }

    &:nth-child(4) {
      width: 15%;
    }

    &:nth-child(5),
    &:nth-child(6),
    &:nth-child(7) {
      width: 7.5%;
    }

    &:nth-child(8) {
      width: 7.5%;
    }
  }
}

.table--recommended-items {
  .th,
  .td {
    &:nth-child(1) {
      width: 35%;
    }
    &:nth-child(2) {
      width: 35%;
    }
    &:nth-child(3) {
      width: 20%;
    }
    &:nth-child(4) {
      width: 10%;
    }
  }
}

.icon-links a {
  position: relative;
  top: 0.2rem;
  display: inline-block;
  margin: 0 0.6rem 0;
  width: $icon-size;
  height: $icon-size;
}

.table__filters {
  @include clearfix;
  display: flex;
  flex-wrap: wrap;
  // justify-content: space-between;
  // width: 102%;
  margin: 0 -1rem 1rem;
  @include breakpoint(mobile_both) {
    margin: 0 -0.5rem 0.5rem;
  }

  &:after,
  &:before {
    display: none;
  }

  label {
    margin-bottom: 0;
    line-height: 1;

    span {
      line-height: 1;
      font-size: $font-size-small;
      margin: 0 0 0.4rem;
    }

    input[type='text'],
    input[type='email'],
    input[type='number'],
    input[type='search'],
    select {
      @extend %filter-styles;
    }

    .select__arrow {
      right: 1rem;
      bottom: 0.8rem;
      @include transform(0.7, 45deg);
      // box-shadow: 0 0 15px 15px rgba($purple-light, 1);
    }

    select:focus + .select__arrow {
      border-color: $purple-light;
    }

    .search__clear {
      top: auto;
      right: 0.8rem;
      bottom: 0.6rem;
      margin: 0;
    }

    // .react-datepicker__close-icon {
    //   padding-right: 0.8rem;
    // }

    .react-datepicker__close-icon::after {
      height: 1.4rem;
      width: 1.4rem;
      padding: 0 0 0.2rem;
      font-size: 1rem;
    }
  }
}

.table__filter {
  width: 14.28571%;
  padding: 0 1rem;
  margin-bottom: 1.5rem;
  @include breakpoint(desktop) {
    width: 16.66667%;
  }
  @include breakpoint(tablet_land) {
    width: 20%;
  }
  @include breakpoint(tablet) {
    width: 20%;
  }
  @include breakpoint(mobile_land) {
    width: 33.33333%;
    padding: 0 0.5rem;
    margin-bottom: 1rem;
  }
  @include breakpoint(mobile) {
    width: 50%;
    padding: 0 0.5rem;
    margin-bottom: 1rem;
  }
}

.app--aside,
.app--item {
  .table__filter {
    width: 33.33333%;
    @include breakpoint(tablet_land) {
      width: 33.33333%;
    }
    @include breakpoint(tablet) {
      width: 33.33333%;
    }
    @include breakpoint(mobile_land) {
      width: 50%;
    }
    @include breakpoint(mobile) {
      width: 50%;
    }
  }
}

.table__wrapper {
  .form__submit {
    margin-top: 3rem;
  }

  label {
    display: block;
    float: none;
    min-width: 10rem;
    // max-width: 16rem;
    margin: 0;

    input[type='text'],
    input[type='number'],
    select,
    .error {
      @extend %filter-styles;
    }

    .error {
      margin-top: 0.5rem;
    }

    input[type='text'],
    input[type='number'] {
      // max-width: 8rem;
      text-align: center;
    }

    .select__arrow {
      right: 1rem;
      bottom: 1.2rem;
      @include transform(0.7, 45deg);
      // box-shadow: 0 0 15px 15px rgba($purple-light, 1);
    }

    select:focus + .select__arrow {
      border-color: $purple-light;
    }
  }
}

.table--half {
  max-width: 50%;
}

.table--narrow {
  max-width: 600px;
  @include breakpoint(tablet_down) {
    max-width: 100%;
  }
}

.table--light {
  tbody tr:nth-child(2n) {
    background-color: $purple-lighter;
  }
}

.table--grouped {
  tbody tr {
    position: relative;
    border-top: 0.1rem solid $purple-light;

    &.-last {
      border-bottom: 0.1rem solid $purple-light;
    }

    td.-right {
      text-align: right;
    }

    &.table__spacer {
      border: 0;
      td {
        padding: 0.3rem 0;
      }
    }

    &.table__group__header {
      border-top: 0;
      border-bottom: 0.1rem solid $purple-light;
      td {
        // font-weight: bold;
        @extend %header-text;
      }
      + tr {
        border-top: 0;
      }
    }

    &.table__group__totals {
      border-bottom: 0.3rem double $purple-light;
      // border-bottom: 1px solid $color-body;
      td {
        font-weight: bold;
      }
    }

    &.-olo {
      background-color: $purple-light;
      border-top: 0.1rem solid $color-bg-body;
    }

    &.-closed {
      background-color: $gray-closed;
      border-top: 0.1rem solid $color-bg-body;
    }

    &.-closed + tr,
    &.-olo + tr {
      border-top: 0.1rem solid $color-bg-body;
    }

    &:not(.-closed).-not-reviewed {
      background-color: $color-error-bg;
      color: $color-error;

      a,
      .table-tooltip {
        color: $color-error;
        &:hover,
        &:active,
        &:focus {
          color: $color-body;
        }
      }
    }

    &.-void {
      opacity: 0.5;
    }

    &.-disabled {
      opacity: 0.25;
    }
  }
}

.table__group__loader {
  position: absolute;
  top: 0;
  left: 0;
  width: 100%;
  height: 100%;
  text-align: center;
  div {
    display: inline-block;
  }
}

.table--hours,
.table--holidays {
  th,
  td {
    width: 14%;
    vertical-align: top;

    label {
      min-width: 0rem;
      @include breakpoint(tablet_land_down) {
        min-width: 0;
      }
      input[type='text'],
      .error {
        max-width: 8rem;
        @include breakpoint(tablet_land) {
          font-size: 1.1rem;
          padding-left: 0.3rem;
          padding-right: 0.3rem;
        }
        @include breakpoint(mobile_both) {
          font-size: 1.1rem;
          padding-left: 0.3rem;
          padding-right: 0.3rem;
        }
      }
    }

    &:first-child {
      width: 25%;

      label {
        input[type='text'],
        .error {
          max-width: 100%;
          min-width: 15rem;
          text-align: left;
          @include breakpoint(tablet_land) {
            padding-left: 0.8rem;
          }
          @include breakpoint(mobile_both) {
            padding-left: 0.8rem;
          }
        }
      }
    }

    &:last-child {
      width: 5%;
    }
  }

  td:last-child {
    padding: 1.5rem 0 0;
  }
}

.table--hours {
  td:first-child {
    padding-top: 1.3rem;
  }
  th,
  td {
    &:nth-child(2),
    &:last-child {
      display: none !important;
    }
  }
}

@media only screen and (max-width: $bp-mobile) {
  .table--hours,
  .table--holidays {
    thead {
      display: none;
    }
    tr {
      display: block;
      float: left;
      width: 100%;
      padding: 0.5rem 0;
      margin-bottom: 1rem;
      border: 1px solid $purple-light;
      border-radius: 0.5rem;
    }
    td {
      display: block;
      float: left;
      width: 50% !important;
      padding: 0.8rem 1rem !important;

      &:nth-child(2n + 1) {
        clear: left;
      }

      &:last-child {
        width: 100% !important;
      }

      label {
        input[type='text'],
        .error {
          max-width: 100%;
          text-align: center !important;
        }
      }
    }
  }

  .table--hours {
    td {
      &:first-child {
        width: 100% !important;
        max-width: 100%;
        padding: 0 1.2rem !important;
        span {
          float: right;
        }
      }
    }
  }
}

.table--customers {
  th,
  td {
    width: 12.5%;

    &:nth-last-child(-n + 3) {
      width: 10%;
    }
  }
}

.table--users {
  th,
  td {
    &:nth-last-child(2) {
      max-width: 20rem;
    }
  }
}

.table--employee-departments {
  th,
  td {
    width: 10%;

    &:nth-child(1),
    &:nth-child(4) {
      width: 25%;
    }
    &:nth-child(3) {
      width: 20%;
    }
  }
}

.item-list {
  margin: 0 0 5rem;

  h2 {
    @extend h3;
    margin-bottom: 1rem;
  }
}

.avails {
  .content__note {
    font-size: $font-size;
    padding-left: 0.5rem;
    margin-bottom: 1rem;
  }
}

.table--avails {
  margin: 1rem 0 3rem;

  th {
    padding: 1.2rem 1rem 1rem;
  }

  td {
    padding: 0.8rem 1rem 0.6rem;
    &:first-child {
      padding: 0.6rem 1rem 0.8rem;
    }
  }

  tr th:first-child,
  tr td:first-child {
    min-width: 20rem;
  }

  .app--item & {
    tr th:first-child,
    tr td:first-child {
      width: auto;
      min-width: 0;
    }
  }

  .app--item .dayparts & {
    tr th:first-child,
    tr td:first-child {
      min-width: 20rem;
      @include breakpoint(mobile) {
        min-width: 12rem;
      }
    }
  }

  .modal__body & {
    tr th:first-child,
    tr td:first-child {
      min-width: 12rem;
    }
  }

  button {
    @extend .btn-link;
  }

  tr td button {
    width: 100%;
    text-align: left;
  }

  tr td .checkbox__custom {
    margin-top: 0.3rem;
    width: 1.6rem;
    height: 1.6rem;
  }

  tr td input.checkbox__input:checked + span.checkbox__custom:before {
    top: 0;
    left: 0.1rem;
    width: 1.6rem;
    height: 1.6rem;
    line-height: 1.4rem;
    text-align: center;
    border: 0;
    content: '\2715';
    font-size: 1.1rem;
    font-weight: bold;
    color: $color-link;
    @include transform(1, 0deg);
  }
}

.table--inventory {
  // margin: 0 0 3rem;

  th,
  td {
    padding: 1.2rem 1rem 1rem;

    &:first-child {
      width: 20rem;
    }

    label {
      input[type='text'],
      input[type='number'],
      .error {
        max-width: 8rem;
      }
    }
  }
}

.table--avails,
.table--inventory {
  tr th,
  tr td {
    &:first-child {
      @include breakpoint(mobile_both) {
        min-width: 15rem !important;
        padding-right: 0;
      }
    }
    &:not(:first-child) {
      @include breakpoint(mobile_both) {
        padding-left: 0.5rem;
        padding-right: 0.5rem;
      }
    }
  }

  label {
    float: none;
    display: block;
    min-width: 7rem;
    margin: 0;
    @include breakpoint(mobile) {
      min-width: 0;
    }

    input[type='text'],
    input[type='number'] {
      @extend %filter-styles;
      padding: 1rem 0.6rem 0.8rem;
      text-align: center;
    }

    &.-error {
      input[type='text'],
      input[type='number'] {
        color: $color-error;
        background-color: $color-error-bg;
        border-color: $color-error-bg;
      }
    }
  }
}

.table-scroll {
  @include breakpoint(mobile) {
    overflow-x: scroll;
  }
}

.daily-items,
.attributes,
.availabilities {
  .table--avails {
    tr {
      th,
      td {
        padding: 0.8rem 0.5rem 0.6rem;
      }
      th {
        @include breakpoint(tablet_down) {
          font-size: $font-size-x-small;
        }
        button {
          @include breakpoint(tablet_down) {
            font-size: $font-size-x-small;
          }
        }
      }
      td {
        label {
          float: none;
          display: inline-block;
          max-width: 10rem;
          @include breakpoint(tablet_land) {
            min-width: 5rem;
          }
          @include breakpoint(tablet) {
            min-width: 5rem;
          }
          @include breakpoint(mobile_both) {
            min-width: 5rem;
          }
          input[type='text'],
          input[type='number'] {
            @include breakpoint(mobile_both) {
              font-size: $font-size-x-small;
            }
          }
        }
      }
    }
  }
}

.attributes {
  .table--avails {
    thead tr:last-child th {
      &:nth-child(3n + 4) {
        padding-right: 1rem;
      }
      &:nth-child(3n + 5) {
        padding-left: 1rem;
      }
    }
    tr td {
      padding: 0.9rem 0.5rem 0.8rem;
      &:nth-child(3n + 4) {
        padding-right: 1rem;
      }
      &:nth-child(3n + 5) {
        padding-left: 1rem;
      }
    }
    label input[type='text'],
    label input[type='number'] {
      padding: 0.8rem 0 0.6rem;
      // font-size: 1rem;
    }
  }
}

.table--permissions {
  th,
  td {
    vertical-align: top;
    &:nth-child(1) {
      width: 25%;
      max-width: 25rem;
    }
    &:nth-child(2) {
      width: 15%;
    }
    &:nth-child(3) {
      text-align: left;
    }
  }
}

.more {
  position: relative;
}

.more__buttons {
  position: absolute;
  z-index: 3;
  top: 110%;
  right: 0;
  display: block;
  width: 16rem;
  padding: 0.6rem 1.8rem 0.8rem;
  border-radius: 0.6rem;
  background-color: $purple-light;
  box-shadow: 0 0 1.5rem 0 rgba($color-body, 0.25);
  @include transition();
  visibility: hidden;
  opacity: 0;

  .more__button {
    width: 100%;
    padding: 0.5rem 0;
    text-align: left;

    &.-remove {
      color: $color-error;
      &:hover,
      &:active,
      &:focus {
        color: $color-body;
      }
    }
  }
}

.btn-more.-active ~ .more__buttons {
  visibility: visible;
  opacity: 1;
  @include translate3d(0, 0, 0);
}

.punches {
  @include breakpoint(mobile) {
    overflow-x: scroll;
  }
}

.table--punches {
  @include breakpoint(tablet_down) {
    font-size: $font-size-x-small;
  }
  .punch--parent td {
    padding-top: 1.1rem;
    padding-bottom: 0.8rem;
    // font-size: 1.3rem;
    font-weight: bold;
  }

  .punch--child td {
    padding-top: 0;

    &:first-child {
      padding-left: 3rem;

      span + button {
        margin-left: 1rem;
      }
    }

    &.-is-overtime {
      color: $color-error;
      font-weight: bold;
    }
  }

  .punch--child.-last td {
    padding-bottom: 1.1rem;
  }
}

.punch__employee {
  margin: 0 0 2.5rem;
  h3 {
    @extend h4;
    margin-left: -2px;
  }
}

table.table--router {
  margin-top: 3rem;
  tbody tr {
    border-bottom: 1px solid $purple-light;
    &.-even {
      background-color: $purple-lighter;
    }
  }

  tr td {
    vertical-align: top;
  }

  tr th,
  tr td {
    width: 10%;
    padding: 0.5rem 0.7rem;
    font-size: 1.1rem;
    text-align: left;
    border-left: 1px solid $purple-light;

    &:nth-child(1) {
      width: 20%;
      border-left: 0;
    }
    &:nth-child(2) {
      width: 12.5%;
    }
    &:nth-child(3) {
      width: 30%;
    }
    // &:nth-child(4) {
    //   width: 15%;
    // }
    &:nth-child(7) {
      width: 7.5%;
    }
  }
}

table.table--manage-orders {
  th,
  td {
    &.-align-left {
      padding-left: 2rem;
    }
    &.-align-right {
      padding-right: 2rem;
    }
  }

  thead tr:first-child th {
    &:nth-child(n + 5) {
      @include breakpoint(mobile_both) {
        display: none;
      }
    }
    &:nth-child(n + 3) {
      @include breakpoint(mobile) {
        display: none;
      }
    }
  }

  thead tr:nth-child(2) th,
  tbody tr td {
    &:nth-child(n + 7) {
      @include breakpoint(mobile_both) {
        display: none;
      }
    }
    &:nth-child(n + 3) {
      @include breakpoint(mobile) {
        display: none;
      }
    }
  }
}

table.table--report {
  // max-width: 96rem;
  // @extend %mono-font;
  // font-size: 1.1rem;

  + table.table--report {
    margin-top: 4rem;
  }

  tr {
    &.-even {
      background-color: $purple-lighter;
    }
  }

  th,
  td {
    // color: $purple-pale;
    // color: $purple-gray;
    color: $color-body;
    max-width: 3rem;
    padding: 1rem 1rem 0.8rem;
    text-align: right;
    // white-space: pre;
    &:first-child {
      max-width: 5rem;
      text-align: left;
    }
  }

  thead tr {
    // td {
    //   text-transform: uppercase;
    //   white-space: pre-wrap;
    // }
    &:last-child {
      border-bottom: 0.1rem solid $purple-light;
      // border-bottom: 0.1rem solid $color-body;
    }

    & + tr td {
      padding-top: 0;
    }
  }

  tbody tr.-net-sales {
    // border-top: 0.1rem solid $color-body;
    td {
      font-weight: bold;
    }
  }

  tbody tr:last-child {
    background-color: $color-bg-body;
    // border-top: 0.1rem solid $purple-light;
    // border-bottom: 0.3rem double $purple-light;
    // border-top: 0.1rem solid $purple-light;
    border-top: 0.1rem solid $color-body;
    border-bottom: 0.2rem solid $color-body;
    // border-top: 0.1rem solid $purple-gray;
    // border-bottom: 0.2rem solid $purple-gray;
    td {
      // color: $color-body;
      font-weight: bold;
      text-transform: uppercase;
    }
  }
}

table.table--dashboard {
  th,
  td {
    min-width: 15rem;
    max-width: 15rem;
    padding: 1rem 1rem 0.8rem;
    &:first-child {
      min-width: 15rem;
      max-width: 15rem;
    }
  }
}

table.table--sales {
  th,
  td {
    &:first-child {
      @extend %ellipsis;
    }
    &:nth-child(3n + 2),
    &:nth-child(3n + 3) {
      @include breakpoint(tablet_down) {
        display: none;
      }
    }
    &:nth-child(3n + 4) {
      @include breakpoint(mobile) {
        display: none;
      }
    }
    &:last-child {
      @include breakpoint(mobile) {
        display: table-cell;
      }
    }
  }
}

.report--sales-by-period table {
  th,
  td {
    width: 16.66667%;
    &:nth-child(2),
    &:nth-child(5),
    &:nth-child(6) {
      @include breakpoint(mobile) {
        display: none;
      }
    }
  }
}

table.table--labor {
  th,
  td {
    @include breakpoint(tablet_down) {
      display: table-cell !important;
    }
  }
}

table.table--hours-for-week,
table.table--tax-summary,
table.table--tender-summary,
table.table--tender-reconciliation {
  tbody tr:last-child {
    border-top: 0.1rem double $purple-light;
    border-bottom: 0.3rem double $purple-light;
    background-color: $color-bg-body;

    td {
      font-weight: bold;
      a {
        color: $color-body;
      }
    }
  }
}

table.table--tax-summary,
table.table--tender-summary {
  thead tr th,
  tbody tr td {
    width: 8rem;
    &:first-child {
      width: 20rem;
    }
  }
}

// MOBILE ADJUSTMENTS

.app--list,
.app--item-list {
  table {
    th,
    .th,
    td,
    .td {
      @include breakpoint(mobile_land) {
        font-size: $font-size-x-small;
      }
      .btn-link {
        @include breakpoint(mobile_land) {
          font-size: $font-size-x-small;
        }
      }
    }
  }
}

.app--list {
  th,
  .th,
  td,
  .td {
    &:not(:first-child) {
      @include breakpoint(mobile) {
        display: none;
      }
    }
  }

  .table--regenerate {
    th,
    td {
      &:nth-child(1) {
        width: 5rem;
        text-align: center;

        button {
          line-height: 1;
        }

        label {
          min-width: 0;
          width: 100%;
        }
      }

      &:nth-child(2) {
        text-align: left;
      }

      &:nth-child(1),
      &:nth-child(2),
      &:nth-child(6) {
        @include breakpoint(mobile) {
          display: table-cell;
        }
        label {
          min-width: 4rem;
        }
      }
    }
  }

  .table--tasks {
    th,
    td {
      &:nth-child(3),
      &:last-child {
        @include breakpoint(mobile) {
          display: table-cell;
        }
        a {
          min-width: 12rem;
        }
      }
    }
  }
}

@media only screen and (max-width: $bp-mobile-land) {
  .app--cards,
  .content__main__table {
    .table__wrapper {
      label {
        top: 0.6rem;
        display: inline-block;
        width: auto;
        min-width: 0;
        margin: 0 0 0 1rem;
      }
    }
    table {
      @include clearfix;

      thead {
        display: none;
      }

      th,
      td {
        display: block;
        float: left;
        width: 100% !important;
        text-align: left;
        border: 0 !important;
        padding: 0 0 0.3rem;
        line-height: 1.3;

        &:empty {
          display: none;
        }

        &::last-child {
          padding-bottom: 0;
        }

        &.-right {
          text-align: left;
        }

        span.mobile-label {
          display: inline;
        }
      }

      td {
        &:first-child a,
        &:first-child .btn-link {
          font-size: $font-size;
        }
        &.gripper {
          display: none;
        }
        // a,
        // .btn-link {
        //   margin: 0.4rem 0;
        // }
      }

      tbody {
        @include clearfix;
        display: block;
        width: 101.25%;
        margin: 0;
      }

      tbody tr {
        display: block;
        float: left;
        width: 32%;
        padding: 1rem 1.5rem;
        margin: 0 1.33333% 1.33333% 0;
        border-radius: 0.5rem;
        border: 0.1rem solid $purple-light !important;
        // box-shadow: 0 2px 4px -1px rgba(0, 0, 0, 0.2);

        // &:nth-child(3n) {
        //   margin-right: 0;
        // }

        &.-even {
          background-color: $color-bg-body;
        }
      }
    }

    table.table--grouped {
      margin-top: -1.5rem;

      tbody tr {
        td.-right {
          text-align: left;
        }
        &.table__spacer {
          display: none;
        }
        &.table__group__header {
          width: 98.66667%;
          // margin: 0 0 1.25%;
          color: $color-white;
          background-color: $color-body;
          border-color: $color-body;
          text-transform: uppercase;
        }
        &.-last {
          border-bottom-width: 0.1rem;
        }
      }
    }
  }
}

@media only screen and (max-width: $bp-mobile) {
  .app--cards,
  .content__main__table {
    table {
      tbody {
        width: 100%;
        tr {
          width: 100%;
          margin: 0 0 0.8rem;
        }
      }
    }

    table.table--grouped {
      margin-top: -1.5rem;

      tbody tr {
        &.table__group__header {
          width: 100%;
        }
      }
    }
  }
}
