/* =============================================================
   API Request
   ============================================================= */

.api-request {
  p {
    margin: 1.5rem 0;
  }

  pre {
    padding: 2rem;
    border-radius: 0.6rem;
    font-size: 1rem;
    background-color: $purple-light;
    color: $purple;
    white-space: pre-wrap; /* css-3 */
    white-space: -moz-pre-wrap; /* Mozilla, since 1999 */
    white-space: -pre-wrap; /* Opera 4-6 */
    white-space: -o-pre-wrap; /* Opera 7 */
    word-wrap: break-word;
  }
}
