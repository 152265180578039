/* =============================================================
   Sticky Nav
   ============================================================= */

.sticky {
  width: 100%;
  height: $header-height;
}

.sticky__inner {
  width: 100%;
  background-color: $color-bg-body;
  @include breakpoint(mobile_both) {
    font-size: $font-size-small;
  }

  .container {
    position: relative;
    height: $header-height;
    display: flex;
    align-items: center;
  }

  .-stuck & {
    position: fixed;
    z-index: 10;
    top: $header-height;
    left: $sidebar-width;
    right: 0;
    width: auto;
    padding: 0 $padding-x;
    @include breakpoint(mobile_both) {
      left: $sidebar-width-mobile;
      padding: 0 $padding-x-mobile;
    }
  }

  .app--content .-stuck &,
  .app--asside .-stuck &,
  .app--item .-stuck & {
    width: $main-item-width;
    right: auto;
    @media only screen and (min-width: $bp-tablet-land + 1px) and (max-width: 1360px) {
      width: auto;
      right: 2 * $sidebar-width;
    }
    @include breakpoint(tablet_both) {
      width: auto;
      right: 2 * $sidebar-width;
    }
    @include breakpoint(mobile_both) {
      width: auto;
      right: 0;
    }
  }
}

.sticky__items {
  @include transition(all, 500ms, ease);
  line-height: 1.4;

  ul {
    display: inline-block;
    padding-bottom: 0.3rem;

    li {
      display: inline-block;
      color: $color-body;

      a {
        cursor: pointer;
      }

      &:after {
        content: ' / ';
        padding: 0 0.5rem 0 0.2rem;
        color: $color-body;
      }

      &:last-child:after {
        display: none;
      }
    }
  }
}
