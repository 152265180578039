/* =============================================================
   Forms
   ============================================================= */

// RESETS

// https://stackoverflow.com/questions/34802552/safari-input-type-search-reset-normalize
input[type='search'] {
  // -webkit-appearance: textfield;
  -webkit-appearance: none;
}

input[type='search']::-webkit-search-decoration {
  -webkit-appearance: none;
}

form {
  @include clearfix;
}

fieldset {
  padding: 0;
  border: 0;
  margin: 0;
}

.form__submit {
  float: left;
  width: 100%;

  .btn {
    margin-top: 1rem;
  }

  .app--item & {
    margin-top: 1rem;
  }
}

.form-back {
  margin-top: 3rem;
  margin-bottom: 5rem;
}

%input-styles {
  width: 100%;
  padding: 1.12em 1.2em 0.9em;
  outline: 0;
  border-radius: 0.6rem;
  @extend %mono-font;
  font-size: 1.3rem;
  line-height: 1.2;
  caret-color: $color-white;
}

// .form__break {
//   @extend %input-styles;
//   clear: both;
//   float: left;
//   padding: 1.12em 1.2em 1.12em;
//   margin: 2rem 0 2rem;
//   // background-color: $purple-lighter;
//   color: $color-white;
//   background-color: $color-body;
// }

.form__break {
  float: left;
  width: 100%;
  padding: 0 0 0.5rem;
  border-bottom: 1px solid $purple-light;
  margin: 2rem 0 2rem;
}

.form__break__title {
  @extend %header-text;
  // color: $color-white;
  // color: $color-link;
}

%input-disabled {
  // color: $purple;
  // background-color: transparent;
  // border: 1px solid $purple-light;
  color: lighten($purple, 8%);
  background-color: lighten($purple-light, 3%);
  border: 1px solid lighten($purple-light, 3%);
  cursor: default;

  &:active,
  &:focus {
    color: lighten($purple, 8%);
    background-color: lighten($purple-light, 3%);
    border: 1px solid lighten($purple-light, 3%);
  }
}

label {
  float: left;
  position: relative;
  width: 100%;
  margin-bottom: 2.5rem;
  @include breakpoint(mobile_both) {
    margin-bottom: 1.5rem;
  }

  &.-half {
    clear: both;
    width: 47.5%;
    @include breakpoint(mobile_both) {
      width: 100%;
    }
  }

  // &.-half:nth-child(even) {
  //   margin-left: 5%;
  // }

  &.-half.-right {
    clear: none;
    margin-left: 5%;
    @include breakpoint(mobile_both) {
      margin-left: 0;
    }
  }

  span {
    display: inline-block;
    padding: 0 0 0.4rem;
    // @extend %heading-font;
    // color: $color-headings;
    font-size: $font-size;
  }

  span.required {
    padding: 0 0 0 0.5rem;
  }

  span.error {
    margin: 1rem 0 0;
  }

  span.full-width {
    display: block;
    width: 100%;
    margin-bottom: 1rem;
  }

  .label__btn {
    padding: 0 0 0 1.25rem;
    // font-size: $font-size-small;
  }

  input[type='text'],
  input[type='email'],
  input[type='password'],
  input[type='number'],
  input[type='search'],
  input[type='tel'],
  input[type='url'],
  input[type='color'],
  textarea,
  select {
    -webkit-appearance: none;
    @extend %input-styles;
    color: $purple;
    background-color: $purple-light;
    border: 1px solid $purple-light;
    @include transition;

    &::selection {
      color: $color-black;
      background-color: $color-white;
    }

    &:active,
    &:focus {
      color: $purple-light;
      background-color: $purple;
      border: 1px solid $purple;
    }
  }

  input {
    @include placeholder(lighten($purple, 20%));
  }

  input[type='color'] {
    width: 100%;
    height: 3.2rem;
    padding: 0.2rem 0.6rem;
    cursor: pointer;
  }

  input[type='range'] {
    width: 100%;
    // cursor: pointer;
  }

  // https://stackoverflow.com/questions/2781549/removing-input-background-colour-for-chrome-autocomplete
  // input:-webkit-autofill,
  // input:-webkit-autofill:hover,
  // input:-webkit-autofill:focus,
  // input:-webkit-autofill:active {
  //   color: $purple !important;
  //   -webkit-box-shadow: 0 0 0 30px $purple-light inset !important;
  // }

  // https://css-tricks.com/snippets/css/change-autocomplete-styles-webkit-browsers/
  input:-webkit-autofill,
  input:-webkit-autofill:hover,
  input:-webkit-autofill:focus,
  textarea:-webkit-autofill,
  textarea:-webkit-autofill:hover,
  textarea:-webkit-autofill:focus,
  select:-webkit-autofill,
  select:-webkit-autofill:hover,
  select:-webkit-autofill:focus {
    // border: 1px solid $purple;
    border: 0;
    -webkit-text-fill-color: $purple;
    -webkit-box-shadow: 0 0 0px 1000px $purple-light inset;
    transition: background-color 5000s ease-in-out 0s;
  }

  input:read-only,
  input[type='text']:read-only,
  input[type='email']:read-only,
  input[type='password']:read-only,
  input[type='number']:read-only,
  input[type='search']:read-only,
  input[type='tel']:read-only,
  input[type='url']:read-only,
  select:disabled {
    // @extend %input-disabled;
    color: lighten($purple, 8%);
    background-color: lighten($purple-light, 3%);
    border: 1px solid lighten($purple-light, 3%);
    cursor: default;

    &:active,
    &:focus {
      color: lighten($purple, 8%);
      background-color: lighten($purple-light, 3%);
      border: 1px solid lighten($purple-light, 3%);
    }
  }

  textarea {
    height: $font-size-big * 10;
    padding: 0.9em 1.2em 0.8em;
    line-height: 1.5;
  }

  select {
    position: relative;
    -webkit-appearance: none;
    -moz-appearance: none;
    appearance: none;
    padding: 1.1em 1.2em 0.92em;
    cursor: pointer;

    option:disabled {
      color: $black;
    }
  }

  .select__arrow {
    position: absolute;
    display: block;
    content: ' ';
    z-index: 2;
    bottom: 1.8rem;
    right: 1.5rem;
    width: 1.1rem;
    height: 1.1rem;
    border-bottom: 2px solid $purple;
    border-right: 2px solid $purple;
    @include transform(1, 45deg);
  }

  &.-error {
    .select__arrow {
      bottom: 7.2rem;
    }
  }

  select:focus + .select__arrow {
    border-color: $purple-light;
  }
}

select[multiple] {
  padding: 0.6rem 0 0.5rem;
  font-size: 1.1rem;

  option {
    padding: 0.3rem 1.5rem;

    &:checked {
      color: $color-white;
      background: $purple;
    }
  }

  &:active,
  &:focus {
    color: $purple;
    background-color: $purple-light;
    border: 1px solid $purple-light;
  }
}

.selection__selected {
  padding: 0.8rem 1.5rem 0;
  @extend %mono-font;
  // color: $gray;
  font-size: 1.1rem;
  line-height: 1.4;
}

.selection__selected__label {
  @extend %header-text;
  font-size: $font-size-small;
}

.selection__selected__items {
  @extend %mono-font;
}

// https://css-tricks.com/snippets/css/turn-off-number-input-spinners/
input[type='number']::-webkit-inner-spin-button,
input[type='number']::-webkit-outer-spin-button {
  -webkit-appearance: none;
  margin: 0;
}

// https://css-tricks.com/webkit-html5-search-inputs/
input[type='search']::-webkit-search-cancel-button {
  -webkit-appearance: none;
  display: none;
}

.search__clear {
  position: absolute;
  z-index: 2;
  top: 50%;
  right: 1rem;
  margin-top: -0.9rem;
  color: $purple;
}

input.search__input:focus + .search__clear {
  color: $color-white;
}

.form .results {
  float: left;
  width: 100%;
  margin: 3rem 0 0;
}

p + .results__list {
  margin-top: 2rem;
}

.results__list li {
  margin: 1rem 0;

  .btn-link {
    text-align: left;
  }
}

.error {
  width: 100%;
  outline: 0;
  border-radius: 0.6rem;
  line-height: 1.2;
  // padding: 1.2em 1.25em 1.12em;
  // font-weight: 400;
  // font-size: $font-size-small;
  font-size: $font-size;
  padding: 0.85em 1.1em 0.95em;
  // @extend %input-styles;
  background-color: $color-error-bg;
  border: 1px solid $color-error-bg;

  p {
    color: $color-error;
  }
}

.error--top {
  margin-bottom: 3rem;

  &.-fields {
    margin-top: -2rem;
  }
}

.error--table {
  margin-bottom: 0;
}

// Checkboxes

label.checkbox {
  position: relative;
  line-height: 1;
  cursor: pointer;

  input.checkbox__input {
    position: absolute;
    border: 0;
    clip: rect(0 0 0 0);
    clip-path: inset(50%);
    width: 1px;
    height: 1px;
    padding: 0;
    margin: -1px;
    overflow: hidden;
    white-space: nowrap;
  }

  span.checkbox__custom {
    display: inline-block;
    position: relative;
    width: 1.9rem;
    height: 1.9rem;
    padding: 0;
    margin: 0 1rem 0 0;
    border-radius: 0.2rem;
    content: '';
    background-color: $purple-light;

    &.-disabled {
      background-color: transparent;
    }
  }

  input.checkbox__input:checked + span.checkbox__custom:before {
    content: '';
    position: absolute;
    width: 1.1rem;
    height: 0.6rem;
    background: transparent;
    top: 0.5rem;
    left: 0.4rem;
    border: 0.2rem solid $color-link;
    border-top: none;
    border-right: none;
    @include transform(1, -45deg);
  }

  input.checkbox__input:disabled + span.checkbox__custom:before {
    border-color: $color-bg-body;
  }

  input.checkbox__input:focus + span.checkbox__custom {
    outline-color: $purple;
    outline-style: auto;
    outline-width: 3px;
  }

  // input.checkbox__input:focus + span.checkbox__custom:before {
  //   border-color: $color-white;
  // }

  span.checkbox__desc {
    position: relative;
    padding: 0;
    top: -0.4rem;
    display: inline;
    line-height: 1.3;
  }

  span.checkbox__expire {
    display: inline-block;
    position: relative;
    width: 6.5rem;
    // height: 1.9rem;
    padding: 0.6rem 0;
    margin: 0;
    border-radius: 0.3rem;
    font-size: $font-size-x-small;
    text-align: center;
    // text-transform: uppercase;
    // letter-spacing: 0.15em;
    color: $purple;
    background-color: $purple-light;

    &.-removed {
      // color: $color-white;
      // background-color: $color-error;
      color: $color-error;
      background-color: $color-error-bg;
    }

    &.-disabled {
      background-color: transparent;
    }
  }
}

.checkbox__wrapper {
  position: relative;
  float: left;
  width: 100%;

  label.checkbox {
    float: none;
    display: inline-block;
    width: auto;
  }
}

label.checkbox--simple {
  margin: 0;
  span.checkbox__custom {
    margin-right: 0;
  }
}

.checkbox-group {
  position: relative;
  float: left;
  width: 100%;
  margin-bottom: 1rem;

  label.checkbox {
    margin-bottom: 1rem;

    span.checkbox__desc {
      font-size: $font-size-small;
    }
  }

  .revenue-center-tender-types & {
    float: left;
    width: 33.33333%;
    @include breakpoint(mobile) {
      width: 100%;
    }
  }
}

.checkbox-group__label {
  display: block;
  width: 100%;
  margin: 0 0 1.2rem;
  @extend %header-text;
}

// Radio Buttons

label.radio {
  position: relative;
  line-height: 1;
  cursor: pointer;

  input.radio__input {
    position: absolute;
    border: 0;
    clip: rect(0 0 0 0);
    clip-path: inset(50%);
    width: 1px;
    height: 1px;
    padding: 0;
    margin: -1px;
    overflow: hidden;
    white-space: nowrap;
  }

  span.radio__custom {
    display: inline-block;
    position: relative;
    width: 2.1rem;
    height: 2.1rem;
    padding: 0;
    margin: 0 1rem 0 0;
    border-radius: 100%;
    content: '';
    background-color: $purple-light;
  }

  input.radio__input:checked + span.radio__custom:before {
    content: '';
    position: absolute;
    top: 0.3rem;
    left: 0.3rem;
    width: 1.5rem;
    height: 1.5rem;
    border-radius: 100%;
    background-color: $purple;
  }

  span.radio__desc {
    position: relative;
    padding: 0;
    top: -0.5rem;
  }
}

// react-datepicker

.react-datepicker-wrapper {
  width: 100%;
}

// Tooltips

.tooltip__trigger {
  position: absolute;
  display: block;
  top: 0.2rem;
  right: 0;
  width: 1.8rem;
  height: 1.8rem;
  border-radius: 1rem;
  text-align: center;
  line-height: 1.4;
  font-size: 1.3rem;
  background-color: $purple-light;
  color: $purple;
  cursor: pointer;
  @include transition();

  &:hover {
    background-color: $purple;
    color: $purple-light;
  }

  // .checkbox-group & {
  //   top: 0.6rem;
  // }
}

.tooltip__content {
  position: absolute;
  z-index: 3;
  display: block;
  max-width: 40rem;
  right: 0;
  bottom: 110%;
  @extend %overlay;
  font-size: 1.2rem;
  line-height: 1.4;
  text-align: center;
  @include transition();
  visibility: hidden;
  opacity: 0;
  @include translate3d(0, 1rem, 0);

  .checkbox & {
    bottom: 130%;
  }
}

.tooltip__trigger:hover ~ .tooltip__content {
  visibility: visible;
  opacity: 1;
  @include translate3d(0, 0, 0);
}

// Modal styles

.modal {
  // label span {
  //   font-size: $font-size-small;
  // }
  .error {
    font-size: $font-size-small;
    padding: 0.85em 1.1em 0.95em;
  }

  .error--top {
    margin: -1rem 0 2rem;
  }

  label span.error {
    margin-top: 0.5rem;
  }
}

.google-map {
  position: relative;
  margin-bottom: 2.5rem;
}

.google-map__map {
  position: relative;
  z-index: 1;
  width: 100%;
  height: 50rem;
  @include breakpoint(mobile_land) {
    height: 30rem;
  }
  @include breakpoint(mobile) {
    height: 20rem;
  }
}

.google-map__reset {
  position: absolute;
  z-index: 2;
  bottom: 1rem;
  left: 1rem;
  color: $color-error;
  background-color: $color-white;
  border: 0.1rem solid $color-error;
  &:hover,
  &:active,
  &:focus {
    color: $color-white;
    background-color: $color-error;
    border: 0.1rem solid $color-error;
  }
}

.google-map.-error {
  .google-map__map {
    height: auto;
  }
  .google-map__reset {
    display: none;
  }
}

.swatches {
  @include clearfix;
  margin: 1rem -0.3rem 0;
}

.swatch {
  float: left;
  width: percentage(1/12);
  padding: 0.3rem;
  margin: 0;

  &.-selected {
    background-color: $color-body;
  }
}

.btn-swatch {
  display: block;
  width: 100%;
  height: 3rem;
  border: 0;
  padding: 0;
  @include transition(all, 0.15s, ease);
  cursor: pointer;
}

.item__categories {
  width: 100%;
  padding: 1.5rem 1.75rem 1.5rem;
  margin: 0 0 1rem;
  border-radius: 0.6rem;
  border: 0.1rem solid $purple-light;
  background-color: $purple-light;

  &.-empty {
    background-color: $color-bg-body;
  }

  & + .item__categories {
    margin: 0 0 4rem;
  }

  h2 {
    // @extend %header-text;
    font-size: $font-size-big;
    margin: 0 0 0.75rem 0;
  }

  p {
    font-size: $font-size-small;
  }

  p + p {
    margin-top: 0.5rem;
  }

  table {
    margin: 1rem 0 1rem;

    thead tr,
    tbody tr {
      background-color: transparent;
      border-bottom: 1px solid $purple-line;
      // &:last-child {
      //   border-bottom: 0;
      // }
    }

    thead tr:last-child th {
      border-bottom: 1px solid $purple-line;
    }

    // tbody tr {
    //   border-bottom: 0.1rem solid $purple-light;
    //   &:last-child {
    //     border-bottom: 0;
    //   }
    // }

    tr th,
    tr td {
      &:first-child {
        width: 32.5%;
        padding-left: 0;
      }
      &:nth-child(2) {
        width: 25%;
      }
      &:nth-child(3) {
        width: 10%;
      }
      &:nth-child(4) {
        width: 15%;
      }
      &:nth-child(5) {
        width: 12.5%;
      }
    }
  }
}

.item__related {
  width: 100%;
  padding: 2rem 2rem 1.75rem;
  border-radius: 0.6rem;
  margin-bottom: 4rem;
  // color: $color-link;
  background-color: $purple-light;

  h2 {
    @extend %header-text;
    margin-left: 0;
    // font-size: $font-size;
    // color: $color-link;
    // font-weight: bold;
  }

  p {
    font-size: $font-size-small;
    // margin: 0.75rem 0 1.25rem;
  }

  h2 + p {
    margin-top: 0.75rem;
  }

  ul {
    margin: 1rem 0;
    list-style: disc inside;
    li {
      // font-size: $font-size-small;
      span {
        position: relative;
        left: -0.5rem;
      }
    }
  }

  p + p {
    margin-top: 1rem;
    font-size: $font-size;
  }

  &.-empty {
    color: $color-error;
    background-color: $color-error-bg;

    // h2 {
    //   color: $color-error;
    // }

    .btn-link {
      color: $color-error;
      text-decoration: underline;
      &:hover,
      &:active,
      &:focus {
        color: $color-body;
      }
    }
  }
}

.input-table {
  .table--avails {
    thead tr th,
    tbody tr td {
      &:first-child {
        text-align: center;
      }
      label {
        display: inline-block;
        width: 10rem;
      }
    }
  }
}

.input-color {
  display: flex;
  justify-content: space-between;
  align-items: center;

  input {
    display: block;
  }

  input + input[type='text'] {
    max-width: 10rem;
    padding: 0.9rem 0 0.7rem;
    margin: 0 0 0 1rem;
    font-size: $font-size-small;
    text-align: center;
  }

  input.input-transparent {
    height: 3.2rem;
  }
}

.input-range {
  display: flex;
  justify-content: space-between;
  align-items: center;

  input {
    display: block;
  }

  input[type='number'] {
    max-width: 6rem;
    padding: 0.9rem 0 0.7rem;
    margin: 0 0 0 1rem;
    font-size: $font-size-small;
    text-align: center;
  }

  &__range {
    width: 100%;
    display: flex;
    div {
      font-size: $font-size-small;
      max-width: 3rem;
      &:first-child {
        text-align: left;
      }
      &:first-child {
        text-align: right;
      }
    }
  }
}

.input-columns {
  display: flex;
  justify-content: center;
  // height: 40rem;
}

.input-column {
  flex: 1;
  display: flex;
  flex-direction: column;
  text-align: center;
  margin: 0 0.5rem;
  border: 1px solid $purple-light;
  border-radius: 0.6rem;

  h2 {
    font-size: $font-size-big;
    flex: 0;
    padding: 1rem;
    border-bottom: 1px solid $purple-light;
  }
}

.input-column-items {
  flex: 1;
  width: 100%;
  padding: 1rem;
  border-radius: 0.6rem;
  transition: background-color ease 0.15s;

  &.-active {
    background-color: lighten($purple-light, 3%);
  }
}

.input-column-item {
  user-select: 'none';
  color: $purple;
  background-color: $purple-light;
  height: 4rem;
  line-height: 4rem;
  text-align: center;
  padding: 0 1rem;
  border-radius: 0.6rem;
  margin: 0 0 1rem;
  transition: background-color ease 0.15s;

  &.-active {
    background-color: darken($purple-light, 3%);
  }
}
