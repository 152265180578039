/* =============================================================
   Page Specific Styles
   ============================================================= */

.page--home {
  .content__header h1 {
    @include breakpoint(mobile) {
      font-size: 220%;
      max-width: 25rem;
    }
  }
}

.page---addresses {
  table tbody tr td:first-child {
    max-width: 20rem;
  }
}

.page--taxes-revenue-centers {
  .checkbox-group {
    width: 50%;
    @include breakpoint(mobile_both) {
      width: 100%;
    }
  }
}

.page--order {
  .error--top.-fields {
    margin-top: 0;
  }
}

.page--revenue-center-attributes {
  .attributes .table--avails tr {
    th {
      font-size: 1rem;
      padding-left: 0.1rem;
      padding-right: 0.1rem;
      button {
        font-size: 1rem;
      }
    }
    td {
      &:first-child {
        min-width: 13rem;
      }
      &:nth-child(n + 1) {
        width: 6rem;
        padding: 0.8rem 0.2rem 0.6rem;
      }
      label {
        max-width: 6rem;
        min-width: 5rem;
        input[type='text'] {
          font-size: 1rem;
          // min-width: 5rem;
        }
      }
    }
  }
}
