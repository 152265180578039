/* =============================================================
   Fonts
   ============================================================= */

/*
 * Web Fonts from colophon-foundry.org
 *
 * The fonts included in this stylesheet are subject to the End User License you purchased
 * from Colophon Foundry. The fonts are protected under domestic and international trademark and
 * copyright law. You are prohibited from modifying, reverse engineering, duplicating, or
 * distributing this font software.
 *
 * (c) 2018 Colophon Foundry
 *
 * Licenced to James Harrington Brandibble Co.
 */

@font-face {
  font-family: 'Mabry';
  src: url('../fonts/mabry-regular-pro.eot');
  src: url('../fonts/mabry-regular-pro.eot?#iefix') format('embedded-opentype'),
    url('../fonts/mabry-regular-pro.woff') format('woff'),
    url('../fonts/mabry-regular-pro.woff2') format('woff2'),
    url('../fonts/mabry-regular-pro.ttf') format('truetype');
  // src: url('../fonts/mabry-regular-pro.woff') format('woff'),
  //   url('../fonts/mabry-regular-pro.woff2') format('woff2');
  font-weight: normal;
  font-style: normal;
}

@font-face {
  font-family: 'Mabry';
  src: url('../fonts/mabry-light-pro.eot');
  src: url('../fonts/mabry-light-pro.eot?#iefix') format('embedded-opentype'),
    url('../fonts/mabry-light-pro.woff') format('woff'),
    url('../fonts/mabry-light-pro.woff2') format('woff2'),
    url('../fonts/mabry-light-pro.ttf') format('truetype');
  // src: url('../fonts/mabry-light-pro.woff') format('woff'),
  //   url('../fonts/mabry-light-pro.woff2') format('woff2');
  font-weight: 300;
  font-style: normal;
}

@font-face {
  font-family: 'Mabry';
  src: url('../fonts/mabry-medium-pro.eot');
  src: url('../fonts/mabry-medium-pro.eot?#iefix') format('embedded-opentype'),
    url('../fonts/mabry-medium-pro.woff') format('woff'),
    url('../fonts/mabry-medium-pro.woff2') format('woff2'),
    url('../fonts/mabry-medium-pro.ttf') format('truetype');
  // src: url('../fonts/mabry-medium-pro.woff') format('woff'),
  //   url('../fonts/mabry-medium-pro.woff2') format('woff2');
  font-weight: bold;
  font-style: normal;
}

/*
 * Legal Disclaimer
 *
 * These Fonts are licensed only for use on these domains and their subdomains:
 * opentender.io
 *
 * It is illegal to download or use them on other websites.
 *
 * While the @font-face statements below may be modified by the client, this
 * disclaimer may not be removed.
 *
 * Lineto.com, 2018
 */

/* Complete */

@font-face {
  font-family: 'Akkurat Mono';
  src: url('../fonts/lineto-akkuratmono-regular.eot');
  src: url('../fonts/lineto-akkuratmono-regular.eot?#iefix')
      format('embedded-opentype'),
    url('../fonts/lineto-akkuratmono-regular.woff2') format('woff2'),
    url('../fonts/lineto-akkuratmono-regular.woff') format('woff');
  // src: url('../fonts/lineto-akkuratmono-regular.woff2') format('woff2'),
  //   url('../fonts/lineto-akkuratmono-regular.woff') format('woff');
  font-weight: normal;
  font-style: normal;
}
