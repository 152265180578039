/* =============================================================
   Mapping
   ============================================================= */

.mapping__revenue-center,
.mapping__mappings {
  @include clearfix;
}

.mapping__loading {
  margin-top: 5rem;
  text-align: center;
}

.mapping__mappings {
  margin: 1rem 0 0;
}

.mapping__import {
  @include clearfix;
  margin: 0 0 3rem;
}

.mapping__mappings__header,
.mapping__import__header {
  margin: 0 0 3rem;

  h2 {
    font-size: 150%;
    margin-bottom: 1rem;
  }
  p + p {
    margin-top: 0.5rem;
  }
}

.mapping__import__header {
  float: left;
  clear: both;
  width: 100%;
  margin: 2rem 0 1rem;
}

.mapping__import__results {
  margin: 3rem 0 0;
  p {
    @extend %header-text;
    margin: 0 0 1rem;
    &.err {
      color: $color-error;
    }
    &.success {
      color: $color-success;
    }
  }
}

.mapping__revenue-centers {
  @include clearfix;
  label.checkbox {
    margin-bottom: 0.8rem;
    span.checkbox__desc {
      top: -0.5rem;
      span {
        font-size: $font-size-small;
        padding: 0;
      }
    }
  }
  .form__submit {
    margin-top: 2rem;
  }
}

.mapping__missing__rc {
  padding: ($header-height + 2rem) 0 0;
  margin: -2rem 0 0;

  h3 {
    font-size: 2.4rem;
    margin: 0 0 1rem;
  }

  h4 {
    font-size: 1.8rem;
    margin: 2rem 0 0;
  }
}

.mapping__missing__group {
  margin: 1.5rem 0 0;
  ul {
    list-style: disc outside;
    margin: 0 0 0 2rem;
    li {
      font-size: $font-size-small;
    }
  }
}

// external menu

.mx__toggle {
  margin: 0 0 4rem;
}

.mx__code {
  margin: 4rem 0 0;
}

.mx__links {
  li {
    margin-top: 0.5rem;
  }
}

.mx__menu {
  padding: ($header-height + 2rem) 0 0;
  margin: -2rem 0 0;
  font-size: $font-size-small;

  h2 {
    font-size: 2.4rem;
    // margin-bottom: 2rem;
  }

  h2 + h4 {
    margin: 1rem 0 0;
  }

  h3 {
    font-size: 1.8rem;
    margin-bottom: 1rem;
  }
}

.mx__menu__header {
  h2 + p {
    margin-top: 1rem;
  }
}

.mx__menu__hours {
  margin-top: 1.5rem;
}

.mx__menu__group {
  margin-top: 3rem;
}

.mx__menu__items {
  // margin-bottom: 2rem;
  > li {
    margin: 1rem 0 0;
    > ul.mx__menu__modifier-group {
      margin: 0 0 1.25rem 3rem;
    }
  }
}

.mx__menu__modifier-group,
.mx__menu__modifier-options {
  list-style: disc outside;
  margin: 0 0 0 2rem;

  li {
    margin: 0.5rem 0 0;
  }
}

.revel-product {
  span {
    display: inline-block;
    width: 20%;
    text-align: center;
    &:first-child {
      width: 50%;
      text-align: left;
      padding-left: 1rem;
    }
    &:nth-child(2) {
      width: 10%;
    }
  }
  &.-header {
    span {
      @extend %header-text;
    }
  }
  + .mx__menu {
    padding: 0;
    margin: 0;
  }
}

.revel-group {
  h3 {
    padding: 0 0 1rem 0.9rem;
    border-bottom: 0.1rem solid $purple-light;
    margin: 0;
  }
  ul li {
    padding: 0.7rem 0 0.8rem;
    margin: 0;
    &:nth-child(even) {
      background-color: $purple-light;
    }
  }
}

.mapping__mappings__inputs {
  max-width: $main-item-width;
  // padding-right: $padding-x;
  // @include breakpoint(mobile_both) {
  //   padding-right: $padding-x-mobile;
  // }
}
