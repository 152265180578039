/* =============================================================
   Modal
   ============================================================= */

.modal__container {
  position: fixed;
  z-index: 101;
  top: 0;
  bottom: 0;
  left: 0;
  right: 0;
  display: flex;
  align-items: center;
  justify-content: center;
  // background-color: rgba($purple, 0.5);
  overflow-y: scroll;
}

.modal__overlay {
  position: fixed;
  z-index: 100;
  top: 0;
  bottom: 0;
  left: 0;
  right: 0;
  background-color: rgba($purple, 0.5);
}

.modal {
  position: relative;
  width: 48rem;
  max-width: 90%;
  padding: 3.5rem 3.5rem 4rem;
  border-radius: 10px;
  // max-height: 90%;
  // overflow-y: scroll;
  background-color: $color-bg-body;
  @include breakpoint(mobile) {
    padding: 2.5rem 2.5rem 3rem;
  }
}

.modal--big {
  align-items: flex-start;
  .modal {
    margin: 2rem;
  }
}

.modal--video {
  .modal {
    width: 96rem;
    max-width: 85%;
    padding: 0.5rem;
    border-radius: 0;
  }

  .modal__close {
    top: -2.5rem;
    right: -3rem;
    color: $color-white;
    &:hover,
    &:active,
    &:focus {
      color: $color-link;
    }
    @include breakpoint(mobile) {
      top: -2rem;
      right: -2.25rem;
    }
  }

  .modal__header {
    display: none;
  }
}

.modal--wide {
  .modal {
    width: 90%;
    max-width: 80rem;
  }
}

.modal--item {
  .modal {
    width: 90%;
    max-width: 64rem;
    @include breakpoint(mobile) {
      padding: 2.5rem 1.5rem 2rem;
    }
  }
  .modal__header {
    h2 {
      @include breakpoint(mobile) {
        font-size: 150%;
      }
    }
  }
}

.modal--order {
  align-items: flex-start;
  padding: 4rem 4rem 4rem ($sidebar-width + 4rem);

  @include breakpoint(tablet_down) {
    padding: 2rem;
  }

  .modal {
    width: 100%;
    max-width: 128rem;
    border-radius: 0;
    // padding-bottom: 2rem;
  }
}

.modal__close {
  position: absolute;
  top: 7px;
  right: 7px;
  @extend .btn-link;
  // color: $purple;
}

.modal__footer {
  margin-top: 3rem;
}

.modal__footer__buttons {
  text-align: right;
}

// .modal__content {
//   width: 100%;
//   max-height: 90%;
//   padding: 3.5rem 3.5rem 4rem;
//   border-radius: 10px;
//   overflow-y: scroll;
// }

.modal__header {
  margin-bottom: 2.5rem;

  h2 {
    @extend h3;
    margin-left: -2px;
  }

  p {
    margin-top: 1rem;
    // font-size: $font-size-small;
    line-height: 1.4;
  }
}

.modal__note {
  margin-bottom: 3rem;
  p {
    line-height: 1.2;
  }
}

.modal--loading {
  text-align: center;

  .modal {
    width: auto;
    padding: 2.5rem;
  }

  .modal__close {
    display: none;
  }

  .modal__header {
    margin-bottom: 1rem;

    h2 {
      @extend h5;
    }

    p {
      margin-top: 1rem;
    }
  }
}

.modal__submitting {
  display: inline-block;
}

.modal__errors__section {
  margin-bottom: 2rem;
  h3 {
    font-size: 1.6rem;
    margin-bottom: 1.2rem;
  }
}

.modal__errors__subsection {
  margin-top: 0.6rem;
  ul {
    list-style: disc inside;
  }
  ul li {
    font-size: $font-size-small;
    color: $color-error;
  }
}

/* Item Modals */

.quantity {
  height: 3rem;
  @include breakpoint(mobile) {
    height: 2rem;
  }

  label {
    position: relative;
    top: -0.3rem;
    float: none;
    display: inline-block;
    width: 5rem;
    margin: 0 1rem;
    @include breakpoint(mobile) {
      width: 3rem;
      margin: 0 0.5rem;
    }
  }
  input[type='number'] {
    @extend %filter-styles;
    text-align: center;
    padding: 0.1rem 0 0;
    height: 3rem;
    line-height: 1;
    font-size: $font-size-small;
    @include breakpoint(mobile) {
      height: 2rem;
      font-size: $font-size-x-small;
    }
  }
  button {
    padding: 0.2rem 0 0;
    width: 3rem;
    height: 3rem;
    // line-height: 3rem;
    padding: 0.7rem;
    &:disabled {
      opacity: 0.2;
    }
    @include breakpoint(mobile) {
      width: 1.8rem;
      height: 1.8rem;
      padding: 0.2rem;
      margin: 0.2rem 0 0;
    }
  }
}

.modal__group {
  margin: 0 0 2rem;
}

.modal__group__header {
  display: flex;
  margin: 0 0 1.5rem;
  justify-content: space-between;
  align-items: center;

  h3,
  p {
    @extend %header-text;
    @include breakpoint(mobile) {
      font-size: $font-size-x-small;
    }
  }
}

.modal__group__settings {
  position: relative;
}

.modal__group__warning.-min,
.modal__group__warning.-max {
  padding: 0.1rem 0.8rem 0.4rem;
  margin: 0 1rem 0 0;
  @include breakpoint(mobile) {
    position: absolute;
    top: -1.7rem;
    right: 0;
    margin: 0;
    font-size: $font-size-x-small;
  }
}

.modal__group__warning.-min {
  @extend %warning;
}

.modal__group__warning.-max {
  @extend %warning-success;
}

.modal__options {
  label.radio {
    // width: 50%;
    margin: 0 0 0.8rem;

    span.radio__desc {
      top: -0.6rem;
      font-size: $font-size-small;
      @include breakpoint(mobile) {
        font-size: $font-size-x-small;
      }
    }
  }
}

.modal__option {
  display: flex;
  width: 100%;
  margin: 0 0 0.8rem;
  align-items: center;
  font-size: $font-size-small;
  @include breakpoint(mobile) {
    font-size: $font-size-x-small;
    margin: 0 0 1.1rem;
  }

  .modal__quantity & {
    font-size: $font-size;
    @include breakpoint(mobile) {
      font-size: $font-size-x-small;
    }
  }
}

.modal__option__name {
  width: 46%;
}

.modal__option__quantity {
  width: 30%;
  text-align: right;

  .quantity {
    display: inline-block;
  }
}

.modal__option__price {
  width: 12%;
  text-align: right;
}

.modal__item.-preview {
  .modal__option__name {
    width: 80%;
  }
  .modal__option__price {
    width: 20%;
  }
}

.modal__quantity {
  display: flex;
  // width: 24rem;
  margin: 4rem 0 0rem;
  justify-content: space-between;
  align-items: center;
}

.modal__quantity__label {
  padding: 0 0 0.2rem;
}

.modal__notes {
  @include clearfix;
  textarea {
    height: 7.3rem;
    padding: 1rem 1.3rem;
    font-size: 1.2rem;
  }
}

.modal__submit {
  display: flex;
  justify-content: space-between;
  align-items: center;
}

.modal__made-for {
  width: 50%;
  label {
    display: flex;
    justify-content: flex-start;
    align-items: center;
    margin-bottom: 0;
    input[type='text'] {
      width: 10rem;
      padding: 1rem 1.3rem;
      margin-left: 2rem;
      font-size: 1.2rem;
      text-align: center;
      // width: 42%;
      // @extend %filter-styles;
      // text-align: center;
      // padding: 0.1rem 0 0;
      // height: 3rem;
      // line-height: 1;
      // font-size: $font-size-small;
      // @include breakpoint(mobile) {
      //   height: 2rem;
      //   font-size: $font-size-x-small;
      // }
    }
  }
}

.modal__refund {
  .modal__option__name {
    width: 27.5%;
  }
  .modal__option__name__wide {
    width: 50%;
  }
  .modal__option__quantity {
    width: 30%;
    text-align: center;
    input[type='number'] {
      color: $color-link;
      &:disabled {
        @extend %input-disabled;
      }
    }
    .refund-input {
      top: 0.1rem;
      width: 8rem;
      margin: 0;
      input[type='number'] {
        &:focus,
        &:active {
          color: $color-white;
          // background-color: $purple-light;
          // border-color: $purple-light;
        }
      }
    }
  }
  .modal__option__price {
    width: 12.5%;
    &:nth-child(3) {
      width: 10%;
    }
    &:last-child {
      width: 7.5%;
    }
  }
}

.modal__refund__header,
.modal__refund__footer {
  span span {
    @extend %header-text;
  }
  span span .btn-link {
    @extend %header-text;
    color: $color-link;
    &:hover,
    &:active,
    &:focus {
      color: $color-body;
    }
  }
}

.modal__refund__header {
  padding-bottom: 0rem;
  border-bottom: 0.1rem solid $purple-light;
  margin-bottom: 1.2rem;
}

.modal__refund__footer {
  padding-top: 1rem;
  border-top: 0.1rem solid $purple-light;
  margin-top: 1.5rem;
}

.modal__refund__buttons {
  margin: 2.5rem 0 1rem;
  text-align: right;
}

/* animate in / out */

$modal-speed: 250ms;

.md-enter,
.md-exit.md-exit-active {
  @include transition(all, $modal-speed, ease);
  opacity: 0;
  visibility: hidden;

  .modal {
    @include transition(all, $modal-speed, ease);
    @include translate3d(0, 10%, 0);
  }
}

.md-enter.md-enter-active,
.md-exit {
  opacity: 1;
  visibility: visible;

  .modal {
    @include translate3d(0, 0, 0);
  }
}
