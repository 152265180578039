/* =============================================================
   Order Mgmt
   ============================================================= */

.app--order-mgmt {
  .header {
    @include breakpoint(mobile) {
      height: 8rem;
    }
  }
  .main {
    @include breakpoint(mobile) {
      padding-top: 8rem;
    }
  }
}

.order-mgmt__header {
  display: flex;
  justify-content: space-between;
  align-items: center;
  flex-wrap: wrap;
  margin: 0 0 1rem !important;
  @include breakpoint(mobile_both) {
    margin: 0 0 1.5rem !important;
  }

  h1 {
    margin: 0.75rem 0 1.5rem;
    @include breakpoint(mobile_both) {
      width: 100%;
      font-size: 150%;
      margin-bottom: 1rem;
    }
    @include breakpoint(mobile) {
      font-size: 125%;
    }
  }
}

.order-mgmt__alert {
  @extend %header-text;
  line-height: 1;
  font-size: 2.2rem;
  padding: 1rem 3rem 1.15rem;
  border-radius: 4rem;
  color: $color-white;
  text-align: center;
  background-color: $color-error;
  animation: blinker 1.5s linear infinite;

  @include breakpoint(tablet_down) {
    font-size: $font-size-big;
  }

  @include breakpoint(mobile_both) {
    width: 100%;
    padding: 1rem 0 1.15rem;
    font-size: $font-size;
  }

  // &__msg {
  //   position: relative;
  //   padding-right: 1.5rem;
  // }
}

@keyframes blinker {
  50% {
    opacity: 0;
  }
}

.alarm-status {
  position: relative;
  top: -0.2rem;
  right: -0.5rem;
  display: inline-block;
  padding-left: 0.5rem;
  font-size: $font-size;
  font-weight: normal;
}

// .btn--alarm {
//   position: absolute;
//   top: 0rem;
//   right: -1.3rem;
//   color: $color-white;

//   &:hover,
//   &:active,
//   &:focus {
//     color: $color-body;
//   }

//   span {
//     display: inline-block;
//     width: 2.2rem;
//     height: 2.2rem;
//   }
// }

.order-filters {
  // @include clearfix;
  width: 100%;
  display: flex;
  align-items: center;
  flex-wrap: wrap;
  justify-content: space-between;
}

.order-filters__group {
  display: flex;
  align-items: center;
  // justify-content: flex-end;
  @include breakpoint(mobile_land) {
    margin-top: 1rem;
  }
  @include breakpoint(mobile) {
    flex-direction: column;
  }
}

.order-filter {
  @include clearfix;
  // display: inline-block;
  margin-left: 3rem;
  &:first-child {
    margin-left: 0;
  }

  @include breakpoint(mobile) {
    width: 100%;
    margin: 0 0 1rem;
  }

  .btn {
    padding: 0.7rem 2.4rem 0.7rem;
  }

  label {
    margin-bottom: 0;
    line-height: 1;
    input[type='text'],
    input[type='search'],
    select {
      @extend %filter-styles;
      padding: 0.7rem 1.1rem 0.6rem;
    }
  }

  .react-datepicker__close-icon::after {
    height: 1.4rem;
    width: 1.4rem;
    padding: 0.1rem 0 0;
    font-size: 1rem;
    color: $color-link;
    border: 0.1rem solid $color-link;
    background-color: transparent;
  }

  .react-datepicker__close-icon:hover::after {
    color: $color-link-hover;
    border-color: $color-link-hover;
  }
}

.order-filter--types {
  .btn {
    @include breakpoint(mobile) {
      width: 25%;
      padding-left: 0;
      padding-right: 0;
      font-size: $font-size-small;
    }
  }
}

.order-filter--inputs {
  label {
    width: 12rem;
    margin-left: 3rem;
    &:first-child {
      margin-left: 0;
    }
    @include breakpoint(mobile) {
      width: 47.5%;
      margin-left: 5%;
      &:first-child {
        margin-left: 0;
      }
    }
  }
}

.order-filter--print,
.order-filter--alarm {
  font-size: $font-size-small;

  .order-filter__icon {
    position: relative;
    top: 0.3rem;
    margin-right: 0.5rem;
  }
}

.order-filter--print {
  margin-left: 1.5rem;
}

.order-secondary {
  display: flex;
  align-items: center;
  justify-content: space-between;
  flex-wrap: wrap;
  width: 100%;
  height: 3rem;
  margin: 1.2rem 0 1.7rem;

  @include breakpoint(mobile_both) {
    height: auto;
  }

  @include breakpoint(mobile) {
    margin-top: 0.4rem;
  }

  p {
    line-height: 1;
  }
}

.order-checkboxes {
  @include clearfix;
  @include breakpoint(mobile_both) {
    margin-bottom: 1rem;
  }

  label.checkbox {
    float: left;
    width: auto;
    margin: 0 0 0 1.5rem;
    &:first-child {
      margin-left: 0;
    }

    @include breakpoint(mobile) {
      width: 50%;
      margin: 0;
      &:nth-child(1),
      &:nth-child(2) {
        margin-bottom: 1rem;
      }
    }

    span.checkbox__custom {
      width: 1.7rem;
      height: 1.7rem;
      margin-right: 0.7rem;
    }

    input.checkbox__input:checked + span.checkbox__custom:before {
      top: 0.4rem;
      left: 0.3rem;
    }

    span.checkbox__desc {
      font-size: $font-size-small;
    }
  }
}

.alarm-hidden {
  position: absolute;
  top: 0;
  right: 0;
  width: 0rem;
  height: 0rem;
}

.alarm-msg {
  width: 100%;
  padding: 1rem 2rem;
  margin: -0.5rem 0 2.5rem;
  border-radius: 0.6rem;
  text-align: center;
  background-color: $color-error-bg;
  p {
    display: inline-block;
    color: $color-error;
    font-weight: bold;
  }
  .btn-link {
    margin-left: 2rem;
    color: $color-error;
    font-weight: bold;
    text-decoration: underline;
    &:hover,
    &:active,
    &:focus {
      color: $color-body;
    }
  }
}

.order-alarm-refresh {
  display: flex;
  align-items: center;
}

.order-alarm {
  display: inline-block;
  margin-right: 2rem;
  font-size: $font-size-small;

  // &__container {
  //   display: flex;
  //   align-items: center;
  // }

  &__text {
    position: relative;
    top: -0.3rem;
    line-height: 1;
  }

  &__icon {
    position: relative;
    display: inline-block;
    top: 0.2rem;
    width: 1.8rem;
    height: 1.8rem;
    margin-left: 0.8rem;
    // &.-muted {
    //   color: $color-error;
    // }
  }
}

.order-refresh {
  position: relative;
  display: inline-block;
  padding: 0.3rem 1rem 0.5rem 3rem;
  margin: 0 -1rem 0 0;
  border-radius: 2rem;
  font-size: $font-size-small;
  color: $color-body;
  background-color: transparent;
  @include transition(all, 0.25s, ease-in-out);

  .btn-link {
    position: absolute;
    top: 0.3rem;
    left: 1rem;
    @include breakpoint(tablet_both) {
      top: 0.1rem;
      left: 0.7rem;
    }
  }

  &.-refreshing {
    color: $color-link;
    background-color: $purple-light;
  }
}

.order-message {
  margin-bottom: 2.5rem;
}

.order-group__header {
  // padding: 0 1rem 0.7rem;
  // border-bottom: 0.1rem solid $purple-light;
  padding: 0 0.3rem;
  margin: 2.5rem 0 2.5rem;

  h2 {
    @extend %header-text;
    font-size: $font-size;
  }

  // h2 {
  //   font-size: 150%;
  //   line-height: 1;
  //   margin: 0;
  //   @include breakpoint(mobile) {
  //     font-size: 115%;
  //   }
  // }
}

.order-cards {
  display: flex;
  flex-wrap: wrap;
  margin: 2rem -0.5rem 2rem;
}

.order-cards__empty {
  padding: 0 0.5rem;
}

$card-padding: 1.8rem;
$btn-height: 3rem;
$btn-padding: 0.9rem;

.order-card {
  width: 20%;
  padding: 0 0.5rem 1rem;
  font-size: $font-size-small;

  @include breakpoint(desktop) {
    width: 25%;
  }
  @include breakpoint(tablet_land) {
    // width: 25%;
    width: 33.33333%;
  }
  @include breakpoint(tablet) {
    width: 33.33333%;
  }
  @include breakpoint(mobile_land) {
    width: 50%;
  }
  @include breakpoint(mobile) {
    width: 100%;
  }

  &.-order-disabled {
    .order-card__container {
      > div:not(.order-card__disabled):not(.order-card__review) {
        opacity: 0.5;
      }
    }
  }

  &__disabled {
    position: absolute;
    z-index: 1;
    top: 0;
    left: 0;
    width: 100%;
    height: 100%;
    display: flex;
    justify-content: center;
    align-items: center;

    &__working {
      padding: 1.5rem 1.5rem 0.8rem;
      // border: 1px solid $purple-light;
      border-radius: 0.6rem;
      background-color: $color-bg-body;
      text-align: center;

      span {
        display: inline-block;
        padding-top: 0.5rem;
      }
    }
  }

  &__review {
    position: absolute;
    z-index: 2;
    top: -1.4rem;
    // right: $btn-padding;
    left: 50%;
    margin-left: -6rem;

    .btn {
      width: 12rem;
      padding: 0.6rem 0 0.8rem;
      font-size: $font-size-small;
      background-color: $color-error;
      &:hover,
      &:active,
      &:focus {
        background-color: $color-body;
      }
    }
  }

  &__type {
    position: absolute;
    z-index: 1;
    top: -0.1rem;
    left: -0.1rem;
    width: 0;
    height: 0;
    border-top: 2.5rem solid $color-body;
    border-right: 2.5rem solid transparent;
    // border-top-left-radius: 0.6rem;
  }

  &__alert {
    // display: none;
    position: absolute;
    z-index: 1;
    line-height: 1;
    top: 1.3rem;
    right: 0.7rem;
    @extend %warning;
    text-transform: capitalize;
    font-weight: bold;
    // padding: 0.4rem 1rem 0.5rem;
    font-size: 1.1rem;
    letter-spacing: 0.05em;
    padding: 0.3rem 0.6rem 0.4rem;
    color: $color-link;
    background-color: $purple-light;
    // color: $purple-light;
    // background-color: $purple-lighter;
  }

  &__alert.-closed {
    // background-color: transparent;
    color: $color-white;
    background-color: $color-body;
  }

  &__alert.-open {
    color: $color-white;
    background-color: $color-success;
  }

  &__alert.-void,
  &__alert.-refunded {
    // color: $color-error;
    // background-color: $color-error-bg;
    color: $color-white;
    background-color: $color-error;
  }

  // &__alert.-today {
  //   @extend %warning;
  //   text-transform: capitalize;
  //   color: $color-white;
  //   background-color: $color-error;
  // }

  // &__alert.-tomorrow {
  //   @extend %warning;
  //   text-transform: capitalize;
  // }

  &__container {
    position: relative;
    height: 100%;
    padding: $card-padding $card-padding (2.2rem + 3 * $btn-height);
    border-radius: 0.6rem;
    border: 0.1rem solid $purple-light;
    background-color: $purple-lighter;
    background-color: $color-white;
    @include transition;
    box-shadow: 0 2px 4px -1px rgba(0, 0, 0, 0.2);
    // box-shadow: 0 4px 6px -1px rgba(0, 0, 0, 0.1),
    //   0 2px 4px -1px rgba(0, 0, 0, 0.06);
  }

  &__footer {
    position: absolute;
    bottom: $btn-padding;
    left: $btn-padding;
    right: $btn-padding;
    height: (3 * $btn-height);

    .btn {
      width: 50%;
      padding: 0;
      margin: 0;
      height: $btn-height;
      line-height: 1;
      font-size: $font-size-small;
      border-radius: 0;
      border: 0.1rem solid $color-white;
      border-bottom: 0;
      color: $color-link;
      background-color: $purple-light;

      &:nth-child(even) {
        border-left: 0;
      }

      &:nth-child(1) {
        border-top-left-radius: 0.3rem;
      }

      &:nth-child(2) {
        border-top-right-radius: 0.3rem;
      }

      &:nth-last-child(2) {
        border-bottom-left-radius: 0.3rem;
        border-bottom: 0.1rem solid $color-white;
        // color: $color-white;
        // background-color: $color-success;
        color: $color-success;
        background-color: $color-success-bg;

        &:hover,
        &:active,
        &:focus {
          color: $color-white;
          background-color: $color-success;
        }
      }

      &:nth-last-child(1) {
        border-bottom-right-radius: 0.3rem;
        border-bottom: 0.1rem solid $color-white;
        color: $color-error;
        background-color: $color-error-bg;

        &:hover,
        &:active,
        &:focus {
          color: $color-white;
          background-color: $color-error;
        }
      }

      &:hover,
      &:active,
      &:focus {
        color: $color-white;
        border-color: $color-white;
        background-color: $color-link;
      }

      &:disabled {
        opacity: 1;
        color: rgba($color-link, 0.5) !important;
        border-color: $color-white !important;
        background-color: lighten($purple-light, 2%) !important;
      }
    }
  }

  &.-main_menu {
    .order-card__container {
      background-color: $purple-light;
      border-color: $purple-light;
    }
  }

  &.-order-alert {
    .order-card__container {
      background-color: $color-error-bg;
      border-color: $color-error-bg;
    }
  }

  &.-closed,
  &.-void {
    .order-card__container {
      background-color: $gray-closed;
      border-color: $gray-closed;

      .order-card__footer {
        .btn {
          color: $color-body;
          background-color: $gray-closed;

          &:hover,
          &:active,
          &:focus {
            color: $color-white;
            background-color: $color-link;
          }

          &:disabled {
            color: rgba($color-body, 0.3) !important;
            border-color: $color-white !important;
            background-color: lighten($gray-closed, 3%) !important;
          }
        }
      }
    }
  }

  // &.-order-disabled {
  //   .order-card__container {
  //     background-color: $purple-light;
  //     border-color: $purple-light;
  //   }
  // }

  &__header {
    // margin-bottom: 0.5rem;
    h2 {
      font-size: $font-size;
      margin: 0;
      font-weight: normal;

      a {
        color: $color-body;
        &:hover,
        &:active,
        &:focus {
          color: $color-link;
        }
      }
    }
  }

  &__section {
    margin: 0.9rem 0 0;

    li {
      margin: 0.1rem 0;

      // &.-today {
      //   color: $color-error;
      // }

      span.-large {
        @extend %warning-success;
        padding: 0rem 0.9rem 0.4rem;
        margin: -0.2rem 0 0 0.6rem;
        color: $color-white;
        background-color: $color-success;
      }
    }
  }
}

.print-order__wrapper {
  position: relative;
}

.print-order__btn {
  position: absolute;
  top: 3.8rem;
  right: 0;
  font-size: $font-size-small;
  @include breakpoint(mobile_land) {
    top: 1.2rem;
  }
  @include breakpoint(mobile) {
    top: 0.5rem;
  }

  span {
    position: relative;
    top: -0.3rem;
    display: inline-block;
    padding: 0 0 0 0.6rem;
  }
}

.print-order {
  &__header {
    margin-bottom: 3rem;
    h2 {
      @extend h3;
      margin-left: -2px;
      @include breakpoint(mobile) {
        font-size: 150%;
      }
    }
    p {
      font-size: $font-size-small;
      text-transform: uppercase;
      margin-bottom: 0.25rem;
    }
  }
}

.print-order__details {
  @include clearfix;

  &.-receipt {
    display: none;
  }

  &__left {
    float: left;
    width: 40%;
    @include breakpoint(mobile_both) {
      width: 100%;
    }
  }

  &__right {
    float: right;
    width: 55%;
    @include breakpoint(mobile_both) {
      width: 100%;
    }
  }

  .receipt__section {
    padding: 0;
    border-radius: 0;
    margin-bottom: 2rem;
    background: none;
  }

  .receipt__row {
    border-bottom: 0;
    border-color: $purple-light;

    &:first-child {
      border-bottom: 1px solid $purple-light;
    }
  }

  // .-compact .receipt__row {
  //   padding-bottom: 0.2rem;
  //   &:first-child {
  //     padding-bottom: 0.6rem;
  //     margin-bottom: 0.3rem;
  //   }
  //   &.-indent + .receipt__row:not(.-indent) {
  //     padding-top: 0.5rem;
  //   }
  // }

  &__details {
    @include clearfix;

    .receipt__row:nth-child(2n) {
      float: left;
      clear: left;
      width: 40%;
    }
    .receipt__row:nth-child(2n + 1) {
      float: right;
      clear: right;
      width: 40%;
    }
    .receipt__row:nth-child(1) {
      float: left;
      width: 100%;
    }
  }

  &__items {
    .receipt__row {
      border-bottom: 1px solid $purple-light;
      &:last-child,
      &.-parent,
      &.-child {
        border-bottom: 0;
      }
      &.-child.-last {
        border-bottom: 1px solid $purple-light;
      }
    }
  }
}

.-print-only {
  display: none;
}

$print-font-size: 11px;
$print-font-size-child: 11px;
$print-color: $color-body;
// $print-font-family: Arial, Helvetica, sans-serif;
$print-font-family: 'Helvetica Neue', Helvetica, Arial, sans-serif;
$print-line: $color-body;
$print-line-child: #ccc;
$print-row-padding: 0.4rem;

@media print {
  %section-header {
    font-family: $print-font-family;
    font-size: 13px;
    font-weight: bold;
    text-transform: none;
  }

  .-print-only {
    display: block;
  }

  .print-order {
    padding: 4rem;
    font-family: $print-font-family;
    font-weight: normal;
    font-style: normal;
    font-size-adjust: auto;
    letter-spacing: 0;
    font-size: $print-font-size;
    color: $print-color;

    h1,
    h2,
    h3,
    h4,
    h5,
    h6,
    p {
      font-family: $print-font-family;
    }

    &__header {
      margin-bottom: 2.5rem;
      h2 {
        margin-left: -1px;
        font-size: 20px;
        font-weight: bold;
      }
      p {
        // margin-top: 0.4rem;
        font-size: $print-font-size;
      }
    }
  }

  .print-order__details {
    &.-desktop.-print-hide {
      display: none;
    }

    &.-receipt.-print-show {
      display: block;
    }

    .print-order__section {
      @include clearfix;
      width: 100%;
      margin: 0 0 2rem;

      &.-signature {
        margin-top: -2rem;
        div:first-child {
          margin-bottom: 2rem;
        }
      }

      &.-packing {
        margin: -1rem 0 3rem;
        div {
          float: left;
          width: 47.5%;
        }
        div + div {
          margin-left: 5%;
        }
      }
    }

    .print-order__underline {
      padding: 1rem 0 0.2rem;
      border-bottom: 1px solid $print-line;
    }

    .receipt__row {
      padding: 0.3rem 0 0.3rem;
      border: 0;
      font-size: $print-font-size;
      color: $print-color !important;

      a {
        color: $print-color !important;
      }

      &:first-child,
      &.-first,
      &.-totals {
        div {
          font-size: $print-font-size;
          font-family: $print-font-family;
        }
      }

      &:first-child {
        padding: 0 0 $print-row-padding * 0.75;
        border-bottom: 1px solid $print-line;
        margin: 0 0 $print-row-padding;
      }

      &.-totals {
        padding: $print-row-padding 0 0.5rem;
        border-top: 1px solid $print-line;
        border-bottom: 2px solid $print-line;
        margin: $print-row-padding 0 $print-row-padding * 2;
      }

      // &.-child,
      &.-indent {
        font-size: $print-font-size-child;
        div:first-child {
          padding-left: 1.5rem;
        }
      }

      &.-child {
        font-size: $print-font-size-child;
        div:first-child {
          &:before {
            position: relative;
            top: 0.2rem;
            content: '';
            display: inline-block;
            width: 12px;
            height: 12px;
            border: 1px solid $print-line;
            margin-right: 1rem;
          }
        }
      }

      &.-child.-last {
        padding-bottom: $print-row-padding * 3;
        border-bottom: 0;
      }

      // &.-child.-last + .receipt__row {
      //   padding-top: $print-row-padding;
      // }
    }

    .print-order__details__items {
      .receipt__row.-item {
        font-size: 12px;
        font-weight: 500;
        padding: $print-row-padding * 1.5 0;
        border-top: 1px solid $print-line-child;
        &:nth-child(2) {
          border-top: 0;
        }
        &.-parent {
          padding-bottom: $print-row-padding * 0.5;
        }
      }

      .receipt__item__name__quantity {
        left: -30px;
        width: 24px;
      }
    }
  }

  .print-order.-receipt {
    padding: 15px;

    .print-order__details {
      .print-order__details__left,
      .print-order__details__right {
        float: left;
        width: 100%;
      }
    }

    .print-order__header {
      h2 {
        font-size: 16px;
        span {
          display: block;
          width: 100%;
          margin-bottom: 0.5rem;
          &:nth-child(even) {
            display: none;
          }
          &::last-child {
            margin-bottom: 0;
          }
        }
      }
    }

    .print-order__details__details {
      .receipt__row {
        width: 100%;
      }
    }

    .print-order__details__items {
      .receipt__row {
        .receipt__item__name__quantity,
        div:nth-child(2) {
          display: none;
        }
      }
    }

    .receipt__row {
      &:first-child,
      &.-first {
        padding: 0.5rem 1rem;
        border: 0;
        background-color: $print-color;
        div {
          color: $color-white;
        }
      }
    }
  }

  table.table--router {
    thead tr:last-child th {
      border-bottom: 1px solid $print-line;
    }

    tbody tr {
      border-bottom: 1px solid $print-line-child;
      &.-even {
        background-color: #efefef;
      }
    }

    tr th,
    tr td {
      font-size: $print-font-size-child;
      border-left: 1px solid $print-line-child;
      &:first-child {
        border-left: 0;
      }
    }
  }
}

.wait-times {
  font-size: $font-size-small;
  margin: -0.2rem 0 0;
  @include breakpoint(tablet_down) {
    margin: 0.4rem 0 0 -1rem;
  }

  button {
    span.-success,
    span.-alert {
      @extend %warning;
      text-transform: uppercase;
      line-height: 1;
      padding: 0.2rem 1rem 0.4rem;
      @include transition();
    }

    span.-success {
      color: $color-success;
      background-color: $color-success-bg;
    }

    &:hover,
    &:active,
    &:focus {
      span.-success {
        color: $color-white;
        background-color: $color-success;
      }
      span.-alert {
        color: $color-white;
        background-color: $color-error;
      }
    }
  }

  &__hours {
    display: inline-block;
    margin-left: 1rem;

    button {
      margin-left: 1rem;

      span {
        text-transform: uppercase;
      }
    }
  }
}

.btn--wait-times {
  padding-bottom: 2.5rem;
}

.order-edit-options {
  .btn-list + .btn-list {
    margin-top: 2rem;

    .btn {
      color: $color-white;
      background-color: $color-link;
      border-color: $color-link;
      &:hover,
      &:active,
      &:focus {
        color: $color-white;
        background-color: $color-body;
        border-color: $color-body;
      }
    }
  }
}

.order-close {
  p:first-child {
    font-size: $font-size-big;
    font-weight: bold;
    color: $color-error;
  }
  .btn-group {
    margin-top: 2.5rem;
  }
}
