/* =============================================================
   Header
   ============================================================= */

.header {
  position: fixed;
  z-index: 10;
  left: $sidebar-width;
  right: 0;
  height: $header-height;
  background-color: $color-bg-header;

  @include breakpoint(tablet_down) {
    // left: $sidebar-width-mobile;
    left: 0;
    height: $header-height-mobile;
  }

  .app--content &,
  .app--aside &,
  .app--item & {
    right: auto;
    width: $main-item-width;
    @media only screen and (min-width: $bp-tablet-land + 1px) and (max-width: 1360px) {
      width: auto;
      right: 2 * $sidebar-width;
    }
    @include breakpoint(tablet_both) {
      width: auto;
      right: 2 * $sidebar-width;
    }
    @include breakpoint(mobile_both) {
      width: auto;
      right: 0;
    }
  }

  // .app--content &,
  // .app--aside & {
  //   right: auto;
  //   width: $main-content-width;
  //   @include breakpoint(mobile_both) {
  //     width: auto;
  //     right: 0;
  //   }
  // }

  .container {
    position: relative;
    // height: $header-height;
    height: 100%;
    display: flex;
    align-items: center;
    justify-content: space-between;
    // @extend %padding;
    padding: 0 $padding-x;
    @include breakpoint(mobile_both) {
      padding: 0 $padding-x-mobile;
    }
  }
}

.header.-hours {
  .container {
    @include breakpoint(tablet_down) {
      flex-direction: column;
      align-items: flex-start;
      justify-content: center;
    }
  }
}

.header__logo {
  width: $sidebar-width;
  padding: 0 2rem;
  line-height: 0;
}

.header__menu {
  display: none;
  line-height: 1;
  position: relative;
  top: 0.2rem;

  @include breakpoint(mobile_both) {
    display: block;
  }

  .app--nav & {
    display: block;
  }

  .btn-link {
    width: 2.4rem;
    height: 2.4rem;
    @include breakpoint(mobile_both) {
      width: 2.8rem;
      height: 2.8rem;
      padding: 0.6rem;
      margin: -0.6rem -0.9rem 0 0;
      border-radius: 0.2rem;
      color: $color-white;
      background-color: $color-link;
      border-color: $color-link;
    }
  }
}

.header__left {
  display: flex;
  align-items: center;
  justify-content: flex-start;
}

.header__sidebar {
  display: none;

  @include breakpoint(tablet_down) {
    display: block;
    line-height: 1;
    position: relative;
    // top: 0.2rem;
    margin: 0 0 0 -1.6rem;
  }

  .btn-link {
    width: 4.4rem;
    height: 4.4rem;
    padding: 1.2rem;
    color: $color-black;
    // background-color: $color-link;
    // border-color: $color-link;
  }
}

.header__crumbs {
  @include transition(all, 500ms, ease);
  line-height: 1.4;
  @include breakpoint(tablet_down) {
    font-size: $font-size-small;
    line-height: 1.2;
  }

  ul {
    display: inline-block;
    padding: 0.3rem 0;

    li {
      display: inline-block;
      color: $gray-light;

      &:after {
        content: ' / ';
        padding: 0 0.5rem 0 0.2rem;
        color: $gray-light;
      }

      &:last-child:after {
        display: none;
      }

      a {
        color: $gray-light;
        &:hover,
        &:active,
        &:focus {
          color: $color-black;
        }
      }
    }
  }
}

.header__nav {
  flex: 1;
  text-align: right;
  // padding-right: 2rem;

  ul {
    display: inline-block;

    li {
      display: inline-block;
      margin: 0 1rem;
      font-size: $font-size-small;

      &:last-child {
        margin-right: 0;
      }

      a {
        color: $color-black;
        &:hover,
        &:active,
        &:focus {
          color: $color-link;
        }
      }
    }
  }
}
