/* =============================================================
   Landing Pages
   ============================================================= */

$input-border: rgba($color-white, 0.3);

.landing {
  position: relative;
  display: flex;
  // height: 100%;
  // flex-direction: column;
  // justify-content: center;
  align-items: stretch;
  height: 100vh;
  @extend %bg-img;
  // background-color: $color-bg-body;
  overflow-y: scroll;
  @include breakpoint(mobile_both) {
    flex-direction: column;
    align-items: center;
    justify-content: left;
  }

  h1,
  h2,
  h3,
  h4,
  h5,
  h6,
  p,
  ol li,
  ul li,
  label span {
    color: $color-white;
  }

  &.landing--error {
    a,
    .btn-link {
      text-align: left;
      color: $color-white;
      text-decoration: underline;
      // padding: 0 0 0.2rem;
      // border-bottom: 1px solid $color-white;
      &:hover,
      &:active,
      &:focus {
        color: $color-body;
        // border-color: $color-body;
      }
    }
  }
}

.landing--reset {
  min-height: 600px;
}

.landing__overlay {
  position: fixed;
  z-index: 1;
  width: 100%;
  height: 100%;
  background-color: rgba(0, 0, 0, 0.9);

  .landing--error & {
    // background-color: $color-error;
    // opacity: 0.5;
    // background-color: rgba(#440d15, 0.9);
    // background-color: rgba(#450d15, 0.9);
    background-color: rgba(darken($color-error, 30%), 0.9);
  }
}

.landing__text {
  display: flex;
  justify-content: center;
  padding: 15rem 0 10rem;
  @include breakpoint(tablet_down) {
    padding: 7.5rem 0 0;
  }
}

.landing__text__content {
  width: 80%;
  max-width: 50rem;

  h1 {
    margin: 0 0 4rem;
    font-size: 240%;
    @include breakpoint(tablet_down) {
      font-size: 200%;
    }
  }

  h2 {
    margin: 4rem 0 4rem;
    font-size: 180%;
  }

  p {
    font-size: 1.6rem;
    @include breakpoint(tablet_down) {
      font-size: 1.4rem;
    }
  }
}

.landing__error {
  display: flex;
  min-height: 100%;
  align-items: center;
  justify-content: center;
}

.landing__error__content {
  width: 80%;
  max-width: 56rem;
  padding: 5rem 0;
  overflow-x: scroll;

  pre {
    color: $color-white;
  }
}

.landing__container {
  display: flex;
  height: 100%;
  align-items: center;
  justify-content: center;
  @include breakpoint(mobile_both) {
    width: 100%;
    max-width: 48rem;
    padding: 0 4rem;
    margin: 0 auto;
    overflow: hidden;
  }
}

.landing__container--brands {
  height: 100%;
  justify-content: center;
  overflow-y: scroll;
  @include breakpoint(mobile_both) {
    width: 100%;
    max-width: 48rem;
    padding: 0 4rem;
    margin: 0 auto;
  }
}

.landing__container,
.landing__container--brands {
  p {
    color: $color-white;
    font-size: 1.8rem;
    @include breakpoint(tablet_down) {
      font-size: 1.6rem;
    }
  }
}

.landing__header {
  margin-bottom: 3rem;
}

.landing__image {
  position: relative;
  z-index: 2;
  width: 50%;
  @include breakpoint(mobile_both) {
    width: 100%;
    margin-top: 10rem;
  }
  @include breakpoint(mobile) {
    margin-top: 2.5rem;
  }
}

.landing__image__content {
  text-align: center;
  margin: 5rem 0 15rem;
  @include breakpoint(mobile_both) {
    width: 100%;
    text-align: left;
    margin-bottom: 0rem;
  }
}

.landing__logo {
  width: 90%;
  max-width: 360px;
  @include breakpoint(tablet_land) {
    max-width: 320px;
  }
  @include breakpoint(tablet_down) {
    max-width: 240px;
  }
}

.landing__message {
  width: 100%;
  margin: 1rem 0 0;
  @include breakpoint(mobile_both) {
    margin: 0rem 0 0;
  }
  p {
    @include breakpoint(mobile_both) {
      font-size: $font-size;
    }
  }
}

.landing__content {
  position: relative;
  z-index: 2;
  width: 50%;
  @include breakpoint(mobile_both) {
    width: 100%;
  }
}

.landing__form {
  position: relative;
  width: 90%;
  max-width: 48rem;
  margin: 5rem 0;
  @include breakpoint(mobile_both) {
    width: 100%;
    max-width: 100%;
  }

  &.landing__form--reset {
    max-width: 60rem;
  }

  .landing__container--brands & {
    margin: 5rem auto 10rem;
  }

  form {
    max-width: 40rem;
  }

  // label span {
  //   font-size: 1.8rem;
  //   margin-bottom: 1rem;
  // }

  input[type='email'],
  input[type='password'],
  input:hover,
  input:focus {
    color: $color-white;
    background-color: transparent;
    border: 1px solid $input-border;
  }

  input:-webkit-autofill,
  input:-webkit-autofill:hover,
  input:-webkit-autofill:focus {
    // border: 1px solid $purple;
    border: 1px solid $input-border;
    -webkit-text-fill-color: $color-white;
    -webkit-box-shadow: 0 0 0px 1000px transparent inset;
    // background-color: none !important;
    transition: background-color 5000s ease-in-out 0s;
  }

  input[type='email'],
  input[type='password'] {
    font-size: 1.3rem;
  }

  input[type='submit'] {
    color: $color-white;
    background-color: $color-link;
    border: 1px solid $color-link;
    &:hover,
    &:active,
    &:focus {
      background-color: $color-link-hover;
      border-color: $color-link-hover;
    }
  }

  .error {
    max-width: 40rem;
    padding: 0.6em 1.1em 0.8em;
    background-color: $color-error;
    border-color: $color-error;

    p {
      font-size: $font-size;
    }
  }
}

$toggle-speed: 0.15s;

.landing__form__login {
  position: relative;
  z-index: 2;
  opacity: 1;
  visibility: visible;
  @include translate3d(0, 0, 0);
  @include transition(all, $toggle-speed, ease-in-out);

  &.-hide {
    opacity: 0;
    visibility: hidden;
    @include translate3d(-100%, 0, 0);
  }
}

.landing__form__reset {
  position: absolute;
  width: 100%;
  top: 0;
  left: 0;
  z-index: 1;
  opacity: 0;
  visibility: hidden;
  @include translate3d(100%, 0, 0);
  @include transition(all, $toggle-speed, ease-in-out);

  &.-show {
    opacity: 1;
    visibility: visible;
    @include translate3d(0, 0, 0);
  }
}

.landing__form__footer {
  float: left;
  width: 100%;
  margin: 2.5rem 0 0;

  ul {
    margin: 0rem 0 2rem;
    list-style: disc inside;
  }

  p {
    font-size: $font-size;
  }

  p + p {
    margin-top: 1.5rem;
  }

  p.landing__success {
    font-size: 1.8rem;
    // color: $color-link-hover;
  }
}

.landing__brands {
  .btn {
    width: 100%;
    padding-top: 1.2rem;
    text-align: left;
    border-radius: 0;
    color: $color-white;
    background-color: transparent;
    border: 1px solid $input-border;
    border-bottom: 0;
    margin: 0;

    + .btn {
      margin: 0;
    }

    span {
      float: left;
    }

    span + span {
      float: right;
      @include breakpoint(mobile_both) {
        display: none;
      }
    }

    span span {
      float: none;
      color: rgba(255, 255, 255, 0.6);
      padding: 0 0 0 0.5rem;
    }

    &:first-child {
      border-top-left-radius: 5px;
      border-top-right-radius: 5px;
    }

    &:last-child {
      border-bottom-left-radius: 5px;
      border-bottom-right-radius: 5px;
      border-bottom: 1px solid $input-border;
    }

    &:hover,
    &:active,
    &:focus {
      background-color: $color-link;
      border-color: $color-link;

      + .btn {
        border-top: 1px solid $color-link;
      }
    }
  }
}
