/* =============================================================
   Typography
   ============================================================= */

@media only screen and (min-width: $bp-mobile-land + 1px) {
  .mobile-land {
    display: none;
  }
}

@media only screen and (min-width: $bp-mobile + 1px) {
  .mobile {
    display: none;
  }
}

@media only screen and (max-width: $bp-mobile) {
  .not-mobile {
    display: none;
  }
}

%body-font {
  font-family: $font;
  line-height: $line-height;
  font-weight: 300;
  font-style: normal;
  color: $color-body;
  letter-spacing: 0.03em;
}

body {
  @extend %body-font;
  font-size: $font-size;
  // -moz-osx-font-smoothing: grayscale;
  // -webkit-font-smoothing: antialiased;
}

::selection {
  color: $color-highlight;
  background-color: $color-highlight-bg;
}

a {
  text-decoration: none;
  color: $color-link;
  @include transition(all, 0.15s, ease);

  &:hover,
  &:active,
  &:focus {
    color: $color-link-hover;
  }
}

%link-light {
  color: $white;
  &:hover,
  &:active,
  &:focus,
  &.active {
    color: $gray-light;
  }
}

%heading-font {
  font-family: $font;
  font-weight: 300;
  font-style: normal;
  // letter-spacing: 0.03em;
  // text-rendering: optimizeLegibility;
  // -moz-osx-font-smoothing: auto;
  // -webkit-font-smoothing: auto;
}

%subheading-font {
  font-family: $font;
  font-weight: 300;
  font-style: normal;
  // text-rendering: optimizeLegibility;
}

%mono-font {
  font-family: $font-mono;
  font-weight: normal;
  font-style: normal;
  // text-rendering: optimizeLegibility;
  // -moz-osx-font-smoothing: auto;
  // -webkit-font-smoothing: auto;
}

%header-text {
  @extend %heading-font;
  font-weight: bold;
  color: $color-headings;
  text-transform: uppercase;
  letter-spacing: 0.05em;
  font-size: $font-size-small;
  @include breakpoint(mobile) {
    font-size: $font-size-x-small;
  }
}

.header-text {
  @extend %header-text;
}

%ellipsis {
  white-space: nowrap;
  overflow: hidden;
  text-overflow: ellipsis;
}

h1,
h2,
h3,
h4,
h5,
h6 {
  @extend %heading-font;
  color: $color-headings;
  line-height: 1;
}

h1 {
  font-size: 260%;
  margin-left: -2px;
  @include breakpoint(tablet_both) {
    font-size: 220%;
  }
  @include breakpoint(mobile_both) {
    font-size: 180%;
  }
}

h2 {
  font-size: 220%;
  margin-left: -2px;
}

h3 {
  font-size: 180%;
  margin-left: -1px;
}

h4 {
  font-size: 150%;
  margin-left: 0px;
}

h5 {
  font-size: 125%;
  margin-left: 0px;
}

h6 {
  font-size: 100%;
  margin-left: 0px;
}

%heading-margins {
  margin-top: 1.5em;

  &:first-child {
    margin-top: 0;
  }
}

.text {
  p {
    margin: 1em 0;
  }

  h1,
  h2,
  h3,
  h4,
  h5,
  h6 {
    @extend %heading-margins;
  }

  h2 {
    @extend h3;
  }
  h3 {
    @extend h4;
  }

  ul {
    padding-left: 1.8rem;
    list-style: disc outside;
  }

  ul li,
  ol li {
    margin-bottom: 0.5em;
    &:last-child {
      margin-bottom: 0;
    }
  }
}

.bold {
  font-weight: bold;
}

.upper {
  text-transform: uppercase;
}

.err,
.error-text {
  color: $color-error;
}

.success {
  color: $color-success;
}

.alert,
.-alert {
  color: $color-error;
  font-weight: bold;
}

.hint {
  color: $color-link;
  font-weight: normal;
}

pre {
  margin: 0;
  @extend %mono-font;
  font-size: 1.2rem;
  line-height: 1.8;
}

.btn,
input[type='button'],
input[type='reset'],
input[type='submit'] {
  -webkit-appearance: none;
  display: inline-block;
  line-height: 1;
  padding: 0.9rem 2.4rem 1.1rem;
  margin: 0;
  border: 0;
  // border: 0.1rem solid $color-link;
  border-radius: 2.4rem;
  font-family: $font;
  font-size: 1.3rem;
  font-weight: 300;
  // letter-spacing: 0.03em;
  text-align: center;
  cursor: pointer;
  color: $color-white;
  background-color: $color-link;
  // background: linear-gradient(to right, $color-link, $color-link-hover);
  @include transition(all, 0.15s, ease);

  &:hover,
  &:focus,
  &:active {
    color: $color-white;
    // border-color: $color-link-hover;
    background-color: $color-link-hover;
    // background: linear-gradient(to right, $color-link-hover, $color-link);
  }

  &:active {
    outline: none;
    background-color: darken($color-link-hover, 5%);
  }

  &.btn--big {
    height: 4.4rem;
    line-height: 4.4rem;
    padding: 0 3rem;
    border-radius: 2.2rem;
  }

  &.btn--small {
    padding: 0.9rem 2.4rem 1rem;
  }

  &.btn--new {
    @extend .btn--big;
    width: 4.4rem;
    padding: 0;
    font-size: 2.4rem;
  }

  &.btn--delete {
    // font-weight: normal;
    background-color: $color-error;
    border-color: $color-error;
    &:hover,
    &:focus,
    &:active {
      background-color: darken($color-error, 10%);
      border-color: darken($color-error, 10%);
    }

    &:disabled {
      &:hover,
      &:active,
      &:focus {
        background-color: $color-error;
        border-color: $color-error;
      }
    }
  }

  &.btn--dark {
    background-color: $color-black;
    &:hover,
    &:focus,
    &:active {
      background-color: $color-link-hover;
    }
  }

  &.btn--secondary {
    padding: 0.8rem 2.2rem 0.9rem;
    border: 0.1rem solid $color-link;
    color: $color-link;
    background-color: transparent;
    &:hover,
    &:focus,
    &:active {
      color: $color-white;
      background-color: $color-link;
      border-color: $color-link;
    }
    &:disabled {
      &:hover,
      &:focus,
      &:active {
        color: $color-link;
        background-color: transparent;
        border-color: $color-link;
      }
    }
  }

  &:disabled {
    opacity: 0.5;
    cursor: default;
    &:hover,
    &:active,
    &:focus {
      background-color: $purple;
      border-color: $purple;
    }
  }
}

.btn-link {
  font-family: $font;
  font-weight: 300;
  font-style: normal;
  font-size: inherit;
  letter-spacing: 0.03em;
  cursor: pointer;
  border: 0;
  padding: 0;
  color: $color-link;
  background-color: transparent;
  @include transition(all, 0.15s, ease);

  &:hover,
  &:focus,
  &:active {
    color: $color-link-hover;
  }

  &.btn-more {
    line-height: 1;
    padding: 0.1rem 0.6rem 0;
    border-radius: 0.3rem;
    margin-top: 0.2rem;
    background-color: $purple-light;
    &:hover,
    &:focus,
    &:active {
      color: $color-white;
      background-color: $color-link;
    }
  }
}

button > * {
  pointer-events: none;
}

.btn + .btn {
  margin-left: 1rem;
}

.btn-group {
  @include clearfix;
}

$btn-group-radius: 0.6rem;

.btn-group__btn {
  cursor: pointer;
  float: left;
  width: 50%;
  height: 3.4rem;
  line-height: 1;
  padding: 0 1rem;
  font-size: 1.3rem;
  font-weight: 300;
  text-align: center;
  color: $color-white;
  background-color: $color-link;
  border: 0.1rem solid $color-white;
  border-top: 0;
  @include transition(all, 0.15s, ease);

  &:nth-child(2n + 0) {
    border-left: 0;
  }

  &:nth-child(1) {
    border-top-left-radius: $btn-group-radius;
  }

  &:nth-child(2) {
    border-top-right-radius: $btn-group-radius;
  }

  &.-last-odd,
  &.-last-2nd-odd {
    border-bottom-left-radius: $btn-group-radius;
  }

  &.-last-even {
    border-bottom-right-radius: $btn-group-radius;
  }

  &:hover,
  &:active,
  &:focus {
    color: $color-white;
    background-color: $color-link-hover;
    border-color: $color-white;
  }

  &:disabled {
    cursor: default;
    opacity: 0.5;

    &:hover,
    &:active,
    &:focus {
      color: $color-white;
      background-color: $color-link;
      border-color: $color-white;
    }
  }

  span {
    line-height: 1.2;
  }
}

.toggle-group {
  display: inline-block;
  .btn {
    border-radius: 0;

    + .btn {
      margin: 0;
      border-left: 0.1rem solid $color-white;
    }

    &:first-child {
      border-top-left-radius: $btn-group-radius;
      border-bottom-left-radius: $btn-group-radius;
    }

    &:last-child {
      border-top-right-radius: $btn-group-radius;
      border-bottom-right-radius: $btn-group-radius;
    }
  }
}

%warning {
  padding: 0.4rem 1rem 0.6rem;
  margin: -0.5rem 0;
  border-radius: 1.2rem;
  line-height: 1;
  font-weight: 300;
  font-size: $font-size-small;
  text-transform: lowercase;
  color: $color-error;
  background-color: $color-error-bg;
}

%warning-success {
  @extend %warning;
  color: $color-success;
  background-color: $color-success-bg;
}

%warning-alert {
  @extend %warning;
  color: $color-link;
  background-color: $purple-light;
}

.warning {
  @extend %warning;
}

.warning-success {
  @extend %warning-success;
}

.warning-alert {
  @extend %warning-alert;
}

.btn-list {
  li {
    button {
      width: 100%;
      padding-top: 1.2rem;
      text-align: left;
      border-radius: 0;
      border-width: 1px;
      border-style: solid;
      border-bottom: 0;
      // color: $color-body;
      // background-color: $color-bg-body;
      // border: 1px solid $purple-light;
      @include breakpoint(mobile) {
        padding: 1rem 1.2rem 1.1rem;
        font-size: $font-size-small;
      }

      > span {
        float: left;

        span {
          float: none;
          display: inline-block;
          padding: 0;
          margin-left: 1rem !important;

          &.-in-zone {
            @extend %warning-success;
            @include breakpoint(mobile) {
              position: absolute;
              right: 0;
              top: -1.3rem;
              padding: 0.2rem 0.8rem 0.4rem;
              font-size: $font-size-x-small;
            }
          }

          &.-is-priority {
            @extend %warning-alert;
            @include breakpoint(mobile) {
              position: absolute;
              right: 5.3rem;
              top: -1.3rem;
              width: 7rem;
              padding: 0.2rem 0.8rem 0.4rem;
              font-size: $font-size-x-small;
            }
          }
        }
      }

      > span + span {
        float: right;
        position: relative;
        @include breakpoint(mobile) {
          padding-top: 0.2rem;
        }
      }
    }

    &:first-child button {
      border-top-left-radius: 5px;
      border-top-right-radius: 5px;
    }

    &:last-child button {
      // border-bottom: 1px solid $purple-light;
      border-bottom-width: 1px;
      border-bottom-style: solid;
      border-bottom-left-radius: 5px;
      border-bottom-right-radius: 5px;
    }
  }

  &.-center {
    text-align: center;

    li button {
      text-align: center;
      span {
        float: none;
      }
    }
  }

  &.-dark {
    li {
      margin-top: 1px;
      button {
        border-color: $color-link;
        border-top: 0;

        &:hover,
        &:active,
        &:focus {
          border-color: $color-link-hover;
        }

        &:disabled {
          &:hover,
          &:active,
          &:focus {
            border-color: $color-link;
          }
        }
      }

      &::first-child {
        margin-top: 0;
      }
    }
  }

  &.-white {
    li {
      button {
        color: $color-white;
        background-color: transparent;
        border-color: rgba($color-white, 0.3);

        &:hover,
        &:active,
        &:focus {
          color: $color-white;
          background-color: $color-link;
          // border-color: $color-link;
        }

        &:disabled {
          &:hover,
          &:active,
          &:focus {
            color: $color-white;
            background-color: $color-link;
            // border-color: $color-link;
          }
        }
      }
    }
  }

  &:not(.-dark):not(.-white) {
    li {
      button {
        color: $color-body;
        background-color: $color-bg-body;
        border-color: $purple-light;
        &:hover,
        &:active,
        &:focus {
          color: $color-white;
          background-color: $color-link;
          border-color: $color-link;
        }

        &:disabled {
          &:hover,
          &:active,
          &:focus {
            color: $color-body;
            background-color: $color-bg-body;
            border-color: $purple-light;
          }
        }
      }
    }
  }
}

.btn-filter {
  display: block;
  width: 100%;
  @extend %filter-styles;
  background-color: $purple-light;
  border-color: $purple-light;
  color: $color-link;

  &:hover,
  &:active,
  &:focus {
    background-color: $color-link;
    border-color: $color-link;
    color: $color-white;
  }
}
