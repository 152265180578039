/* =============================================================
   Message
   ============================================================= */

.message {
  // position: absolute;
  // z-index: 11;
  // top: 1.6rem;
  // right: $padding-x;
  position: fixed;
  z-index: 15;
  bottom: 1.6rem;
  right: 1.6rem;
  color: $purple-light;
  background-color: $color-link;
  // color: $green-light;
  // background-color: $green;
  line-height: 1.2;
  padding: 0.8rem 2rem 1rem;
  border-radius: 0.6rem;
  @include transition(all, 500ms, ease-in-out);
  @include translate3d(0, 0, 0);

  p {
    font-size: $font-size-small;
  }
}

.flash-enter,
.flash-exit.flash-exit-active {
  opacity: 0;
  visibility: hidden;
  @include translate3d(0, 20%, 0);
}

.flash-enter.flash-enter-active,
.flash-exit {
  opacity: 1;
  visibility: visible;
  @include translate3d(0, 0, 0);
}
