/* =============================================================
   Mixins
   ============================================================= */

@mixin clearfix {
  & {
    *zoom: 1;
  }
  &:before,
  &:after {
    content: '';
    display: table;
  }
  &:after {
    clear: both;
  }
}

@mixin breakpoint($point) {
  @if $point == mobile {
    @media only screen and (max-width: $bp-mobile) {
      @content;
    }
  } @else if $point == mobile_land {
    @media only screen and (min-width: $bp-mobile + 1px) and (max-width: $bp-mobile-land) {
      @content;
    }
  } @else if $point == mobile_both {
    @media only screen and (max-width: $bp-mobile-land) {
      @content;
    }
  } @else if $point == tablet {
    @media only screen and (min-width: $bp-mobile-land + 1px) and (max-width: $bp-tablet) {
      @content;
    }
  } @else if $point == tablet_down {
    @media only screen and (max-width: $bp-tablet) {
      @content;
    }
  } @else if $point == tablet_land {
    @media only screen and (min-width: $bp-tablet + 1px) and (max-width: $bp-tablet-land) {
      @content;
    }
  } @else if $point == tablet_both {
    @media only screen and (min-width: $bp-mobile-land + 1px) and (max-width: $bp-tablet-land) {
      @content;
    }
  } @else if $point == tablet_land_down {
    @media only screen and (max-width: $bp-tablet-land) {
      @content;
    }
  } @else if $point == desktop {
    @media only screen and (min-width: $bp-tablet-land + 1px) and (max-width: $bp-desktop) {
      @content;
    }
  } @else if $point == desktop_down {
    @media only screen and (max-width: $bp-desktop) {
      @content;
    }
  } @else if $point == wide {
    @media only screen and (min-width: $bp-desktop + 1px) {
      @content;
    }
  }
}

// $breakpoints: (
//   'mobile': (
//     max-width: 768px
//   ),
//   'tablet': (
//     only screen and
//       (
//         max-width: $bp-tablet-land
//       )
//   ),
//   'desktop': (
//     min-width: $bp-tablet-land + 0.1rem
//   )
// ) !default;

// @mixin respond-to($breakpoint) {
//   // If the key exists in the map
//   @if map-has-key($breakpoints, $breakpoint) {
//     // Prints a media query based on the value
//     @media #{inspect(map-get($breakpoints, $breakpoint))} {
//       @content;
//     }
//   }

//   // If the key doesn't exist in the map
//   @else {
//     @warn "Unfortunately, no value could be retrieved from `#{$breakpoint}`. "
//         + "Available breakpoints are: #{map-keys($breakpoints)}.";
//   }
// }

@mixin placeholder($color, $opacity: 1) {
  &::placeholder {
    color: $color;
    opacity: $opacity;
  }

  &:-ms-input-placeholder {
    color: $color;
  }

  &::-ms-input-placeholder {
    color: $color;
  }
}

@mixin linear-gradient(
  $degrees: 0,
  $top-color: #000,
  $top-stop: 0%,
  $bottom-color: #fff,
  $bottom-stop: 100%
) {
  background: $top-color;
  background: -moz-linear-gradient(
    $degrees,
    $top-color $top-stop,
    $bottom-color $bottom-stop
  );
  background: -webkit-linear-gradient(
    $degrees,
    $top-color $top-stop,
    $bottom-color $bottom-stop
  );
  background: linear-gradient(
    $degrees,
    $top-color $top-stop,
    $bottom-color $bottom-stop
  );
}

@mixin radial-gradient(
  $shape: circle,
  $color0: #333,
  $color1: #111,
  $stop1: 50%,
  $color2: #000,
  $stop2: 60%
) {
  background: $color2;
  background: -moz-radial-gradient(
    $shape,
    $color0 0%,
    $color1 $stop1,
    $color2 $stop2
  );
  background: -webkit-radial-gradient(
    $shape,
    $color0 0%,
    $color1 $stop1,
    $color2 $stop2
  );
  background: radial-gradient(
    $shape,
    $color0 0%,
    $color1 $stop1,
    $color2 $stop2
  );
}

@mixin transition($property: all, $time: 0.15s, $type: ease, $delay: 0s) {
  transition: $property $time $type #{$delay};
}

@mixin transform($scale: 1, $rotate: 45deg) {
  -webkit-transform: scale($scale) rotate($rotate);
  -moz-transform: scale($scale) rotate($rotate);
  -ms-transform: scale($scale) rotate($rotate);
  -o-transform: scale($scale) rotate($rotate);
  transform: scale($scale) rotate($rotate);
}

@mixin translate3d($x, $y, $z) {
  -webkit-transform: translate3d($x, $y, $z);
  -moz-transform: translate3d($x, $y, $z);
  -ms-transform: translate3d($x, $y, $z);
  -o-transform: translate3d($x, $y, $z);
  transform: translate3d($x, $y, $z);
}
