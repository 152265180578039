/* =============================================================
   Aside
   ============================================================= */

.aside {
  position: fixed;
  top: 0;
  bottom: 0;
  right: 0;
  left: $sidebar-width + $main-content-width;
  // @extend %bg-img;
  // background-color: $color-body;
  @include radial-gradient(ellipse, #222, #000, 70%, #000, 85%);

  @media only screen and (min-width: $bp-tablet-land + 1px) and (max-width: 1360px) {
    left: auto;
    right: 0;
    width: $sidebar-width * 2;
  }

  @include breakpoint(tablet_both) {
    left: auto;
    right: 0;
    width: $sidebar-width * 2;
  }

  @include breakpoint(mobile_land) {
    left: auto;
    right: 0;
    width: $sidebar-width * 2;
    display: none;
  }

  @include breakpoint(mobile) {
    display: none;
  }
}

.aside__container {
  display: flex;
  height: 100%;
  align-items: center;
  justify-content: center;
  // background-color: rgba(0, 0, 0, 0.75);
}

.aside__content {
  width: 100%;
  text-align: center;
  // margin: 5rem 0 10rem;
}

.aside__logo {
  width: 75%;
  max-width: 260px; // matches the app loading image width
  // opacity: 0.3;
  @media only screen and (max-width: 1360px) {
    max-width: 220px;
  }
}

.aside__message {
  width: 100%;
  margin: 1rem 0 0;

  p {
    color: $color-white;
    color: #222222;
    font-size: 2rem;
  }
}
