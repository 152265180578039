/* =============================================================
   Variables
   ============================================================= */

// Breakpoints -- MOVED TO _resets.scss
// $bp-mobile: 680px;
// $bp-mobile-land: 900px;
// $bp-tablet: 1200px;
// $bp-tablet-land: 1480px;

// Layout
$header-height: 5rem;
$header-height-mobile: 5rem;
$header-height-pop: 5rem;
$footer-height: 6rem;
$sidebar-width: 20rem;
$sidebar-width-mobile: 26rem;
$sidebar-footer-height: 6rem;
$sidebar-footer-height-mobile: 4rem;
$main-content-width: 76rem;
$main-item-width: 76rem;
$nav-width: 20rem;
$nav-title-mobile-padding-top: 3rem;
$pop-width: 18rem;
$padding-x: 4rem;
$padding-x-mobile: 2rem;
$icon-size: 1.4rem;
$icon-size-mobile: 1.6rem;

// @function rempx($size) {
//   $pxSize: $size / 1rem * 10;
//   @return #{$pxSize}px;
// }
// $bp-tablet: rempx($main-item-width + $sidebar-width);
// $bp-tablet-land: rempx($main-item-width + 3 * $sidebar-width);

$fade-up-speed: 500ms;

// Colors
$black: #000;
$black-gray: #111;
// $gray-dark: #1a1a1a;
$gray-dark: #1c1b1d;
$gray-darker: #141315;
$gray: #666;
// $gray-light: #999;
$gray-light: #afabb0;
$gray-white: #ddd;
$gray-closed: #e5e5e5;
$near-white: #fbfafd;
$white: #fff;
$purple: #5a5aff;
// $purple-pale: #a9a1d6;
// $purple-pale: #9a90da;
$purple-pale: #a49bdc;
$purple-gray: #535380;
// $purple-pale: darken($purple-light, 6%);
$purple-light: #edeaff;
$purple-lighter: lighten($purple-light, 2.5%);
// $purple-line: $purple-pale;
$purple-line: darken($purple-light, 3%);
$blue: #1ae2de;
$blue-light: #e8fcfb;
// $red: #ff3d58;
// $red: rgba(235, 73, 98, 1);
// $red: #eb4962;
$red: #f72f4c;
// $red-light: rgba(235, 73, 98, 0.18);
$red-light: #fbdee3;
$red-dark: #cb273f;
$green: #13a086;
// $green: #20ce99;
// $green: #169976;
// $green-light: #11fab4;
// $green: #15af5a;
$green-light: #e6fff3;

// Fonts
// $font: 'Mabry', Helvetica, Arial, sans-serif;
$font: 'Mabry', serif;
// $font-mono: 'Akkurat Mono', monospace;
$font-mono: 'Akkurat Mono', serif;

// Font Sizes
$font-size-big: 1.6rem;
$font-size: 1.4rem;
$font-size-small: 1.2rem;
$font-size-x-small: 1rem;
$line-height: 1.5;

// Colors

$color-black: $black;
$color-white: $white;
$color-highlight: $white;
$color-highlight-bg: $purple;

$color-bg-body: $color-white;
$color-bg-header: $color-white;
$color-bg-sidebar: $color-black;
$color-bg-nav: $gray-dark;
$color-bg-pop: $purple-light;

$color-body: $color-black;
$color-body-2nd: $gray-light;
$color-headings: $color-black;
$color-sidebar: $color-white;
$color-sidebar-active: $purple;
$color-link: $purple;
$color-link-hover: $blue;
$color-error: $red;
$color-error-bg: $red-light;
$color-success: $green;
$color-success-bg: $green-light;

// %page-transition {
//   @include transition(all, 0.35s, ease-in);
// }

%padding {
  padding: 0 $padding-x;
}

%bg-img {
  background-repeat: no-repeat;
  background-position: center center;
  background-attachment: scroll;
  background-size: cover;
}

%filter-styles {
  padding: 1rem 1.1rem 0.8rem;
  outline: 0;
  border-radius: 0.5rem;
  @extend %mono-font;
  font-size: 1.1rem;
  line-height: 1.1;
}

%overlay {
  padding: 0.6rem 1rem 0.8rem;
  border-radius: 0.6rem;
  background-color: rgba($color-body, 0.85);
  color: $color-white;
}
