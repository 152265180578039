/* =============================================================
   Quill WYSIWYG Editor
   ============================================================= */

.ql-toolbar.ql-snow {
  background-color: $purple-light;
  padding: 1.5rem 1.5rem 1.4rem;
  border: 0;
  border-radius: 0.6rem;

  .ql-formats {
    padding: 0;
  }

  .ql-stroke {
    stroke: $color-body;
  }

  button,
  .ql-picker {
    background-color: $color-white;
    border-radius: 0.2rem;
    color: $color-body;

    &:hover,
    &:focus,
    &.ql-active {
      color: $color-link;
      .ql-stroke {
        stroke: $color-link;
      }
    }

    .ql-picker-label:hover,
    .ql-picker-label.ql-active,
    .ql-picker-item:hover,
    .ql-picker-item.ql-selected {
      color: $color-link;
    }

    + button,
    + .ql-picker,
    + select + button {
      margin-left: 0.2rem;
    }
  }
}

.ql-container.ql-snow {
  border: 0;
  border-radius: 0.6rem;
  margin-top: 0.3rem;
  background-color: $purple-light;

  .ql-editor {
    padding: 0 1.2em;
    // @extend %mono-font;
    line-height: 1.5;
    // font-size: 1.3rem;
    color: $purple;

    h1,
    h2,
    h3,
    h4,
    h5,
    h6 {
      // @extend %mono-font;
      color: $purple;
      margin: 1.5rem 0;
    }

    h1 {
      font-size: 2.25em;
    }

    h2 {
      font-size: 2em;
    }

    h3 {
      font-size: 1.75em;
    }

    h4 {
      font-size: 1.5em;
    }

    h5 {
      font-size: 1.25em;
    }

    h6 {
      font-size: 1em;
    }

    p,
    ol,
    ul,
    ol li,
    ul li {
      margin: 1.5rem 0;
    }
  }
}
