/* =============================================================
   Sections
   ============================================================= */

.sections__wrapper {
  margin: 3.5rem 0;

  &.-search {
    margin-bottom: 0;
  }

  &.-search + .sections__wrapper {
    margin-top: 0;
  }
}

.section__btn {
  position: relative;
  display: block;
  width: 16rem;
  max-width: 16rem;
  min-width: 16rem;
  padding: 1rem 0;
  overflow: hidden;
  @include transition(all, 0.15s, ease);
}

.section__title {
  position: relative;
  z-index: 2;
  display: inline-block;
  padding: 0 0.75rem;
  margin: 0 -1rem 0 -0.75rem;
  font-size: 1.4rem;
  color: $color-body;
  background-color: $color-bg-body;
  @include translate3d(0, 0, 0);
  @include transition(all, 0.15s, ease);
}

.section__line {
  position: absolute;
  z-index: 1;
  top: 50%;
  left: 0;
  width: 100%;
  height: 0.1rem;
  margin-top: 0.1rem;
  background-color: $purple-light;
  @include translate3d(0, 0, 0);
  @include transition(all, 0.15s, ease);
}

.section__desc {
  padding: 0.5rem 0.5rem 0.5rem 2rem;
  line-height: 1.2;
  font-size: $font-size-small;
  color: $color-body;
  @include transition(all, 0.15s, ease);
}

.section__icon {
  display: none;
  width: $icon-size-mobile;
  height: $icon-size-mobile;
  color: $color-white;
  @include breakpoint(tablet_down) {
    display: inline;
  }
}

.section a {
  display: flex;
  align-items: center;
  border-radius: 0.5rem;

  &:hover,
  &:active,
  &:focus {
    background-color: $purple-light;

    .section__title {
      color: $color-link;
      background-color: $purple-light;
      @include translate3d(1.25rem, 0, 0);
    }

    .section__line {
      background-color: $color-link;
      @include translate3d(1.25rem, 0, 0);
    }

    .section__desc {
      color: $color-link;
    }
  }
}

.section__search {
  display: flex;
  align-items: center;
  border-radius: 0.5rem;
}

.content__search {
  @include clearfix;
  display: inline-block;
}

.content__search__input {
  @include clearfix;
  display: flex;
  align-items: center;
  label {
    float: none;
    width: 25rem;
    margin: 0 1rem 0 0;
    input[type='search'] {
      padding: 1.12em 1.2em 0.9em;
      font-size: $font-size-small;
    }
  }
}

@media only screen and (max-width: $bp-tablet) {
  .sections__wrapper {
    margin: 2rem 0;
  }

  .sections {
    li {
      overflow: hidden;
      // margin-bottom: 0.1rem;
      border-bottom: 0.1rem solid #222;
      &:first-child {
        border-top-left-radius: 0.5rem;
        border-top-right-radius: 0.5rem;
      }
      &:last-child {
        // margin: 0;
        border-bottom: 0;
        border-bottom-left-radius: 0.5rem;
        border-bottom-right-radius: 0.5rem;
      }
    }
  }

  .sections__wrapper.-search .sections li {
    border: 0;
    border-radius: 0;
  }

  .section a {
    height: 4rem;
    border-radius: 0;
    padding: 0 1.5rem;
    // background-color: transparent;
    // 2 per row
    // padding: 0 0.5rem 0 1rem;
    // background-color: $color-body
    // 1 row black
    background-color: $color-body;

    .section__line,
    .section__desc {
      display: none !important;
    }

    .section__btn {
      width: 100%;
      min-width: 0;
      max-width: 100%;
      line-height: 1;
      overflow: visible;
      // black
      padding: 0 0 0.2rem;
    }

    .section__title {
      color: $color-body;
      background-color: transparent;
      // black
      color: $color-white;
    }

    &:hover,
    &:active,
    &:focus {
      background-color: $color-body;

      .section__title {
        color: $color-white;
        background-color: transparent;
        @include translate3d(0, 0, 0);
      }
    }
  }
}

@media only screen and (max-width: $bp-mobile) {
  .sections__wrapper.-search {
    margin-bottom: 2.5rem;
  }

  .section__search {
    flex-direction: column;
    align-items: flex-start;
    .section__btn {
      width: 100%;
      max-width: 100%;
      padding: 0;
      display: none;
    }
    .section__line {
      display: none;
    }
    .section__desc {
      width: 100%;
      padding: 0;
    }
  }

  .content__search__input {
    width: 100%;

    label {
      width: auto;
      flex-grow: 2;
      input[type='search'] {
        color: $color-white;
        background-color: $color-body;
        border-color: $color-body;
        @include placeholder($color-white, 1);
      }
    }
  }

  .content__search__message {
    display: block;
    width: 100%;
    margin: 1.5rem 0 0;
    span {
      font-size: $font-size;
    }
  }
}
