/* =============================================================
   Order
   ============================================================= */

$gray-line: lighten($gray-dark, 7%);

$purple-bg: #191946;
// $purple-bg: #11112f;
$purple-line: #292974;
$purple-line: #21215d;
$purple-text: #9696ff;
$purple-text: #7878ff;

.app--order .main {
  padding-right: 2 * $sidebar-width;
  @include breakpoint(tablet_down) {
    padding-right: 0;
  }
}

.order-sidebar {
  position: fixed;
  z-index: 12;
  top: 0;
  bottom: 0;
  right: 0;
  width: $sidebar-width * 2;
  padding: 2rem;
  color: $color-sidebar;
  background-color: $color-bg-sidebar;
  overflow-y: scroll;
  @extend %sidebar-transition;
  @include translate3d(0, 0, 0);

  @include breakpoint(tablet_down) {
    right: -($sidebar-width * 2);
  }

  @include breakpoint(mobile) {
    right: -87%;
    width: 87%;
  }

  &.-open {
    @include breakpoint(tablet_down) {
      @include translate3d(-($sidebar-width * 2), 0, 0);
    }
    @include breakpoint(mobile) {
      @include translate3d(-100%, 0, 0);
    }
  }

  // .btn {
  //   padding: 0.7rem 1.1rem 0.7rem;
  //   border: 1px solid $color-white;
  //   &:hover,
  //   &:focus,
  //   &:active {
  //     border: 1px solid $color-body;
  //     background-color: $color-body;
  //   }
  // }
}

.order__alerts {
  position: fixed;
  z-index: 12;
  bottom: 2rem;
  right: 0;
  width: $sidebar-width * 2;
  padding: 0 2rem;
  @include breakpoint(mobile) {
    width: 90%;
  }

  ul {
    width: 100%;
    margin: 0;
    li {
      float: left;
      position: relative;
      width: 100%;
      padding: 0.8rem 3rem 1.1rem 1.5rem;
      border-radius: 0.5rem;
      margin: 1rem 0 0;
      line-height: 1.2;
      color: $color-white;
      background-color: $color-error;
      // color: $color-body;
      // background-color: $color-white;
      @include transition(all, 0.25s, ease);

      .btn-link {
        position: absolute;
        top: 50%;
        right: 0;
        width: 3rem;
        margin-top: -8px;
        text-align: center;
        color: $color-white;
        &:hover,
        &:active,
        &:focus {
          color: $color-body;
        }
      }
    }
  }
}

.order__container {
  display: flex;
  height: 100%;
  flex-direction: column;
  justify-content: flex-start;
}

.order__close {
  display: none;
  padding: 0 0 0.9rem;
  text-align: right;

  @include breakpoint(tablet_down) {
    display: block;
  }

  .btn-link {
    color: $color-white;
    vertical-align: top;
    span {
      display: inline-block;
      &:first-child {
        width: 1.8rem;
        height: 1.8rem;
        margin: 0;
      }
      &:last-child {
        position: relative;
        top: -0.4rem;
      }
    }
  }
}

.order__submit {
  margin: 0 0 2rem;
  text-align: center;

  .btn {
    // display: block;
    // float: left;
    width: 50%;
    border-radius: 5px;
    &:first-child {
      border-top-right-radius: 0;
      border-bottom-right-radius: 0;
    }
    &:last-child {
      margin-left: 0;
      border-top-left-radius: 0;
      border-bottom-left-radius: 0;
    }
    @include breakpoint(mobile) {
      font-size: $font-size-small;
      padding: 1rem 0 1.1rem;
    }
  }

  .btn--delete {
    color: $gray-light;
    background-color: $gray-dark;
    border-color: $gray-dark;
    &:hover,
    &:focus,
    &:active {
      color: $color-white;
      background-color: $color-error;
      border-color: $color-error;
    }
  }
}

.order__clear {
  margin: 0 0 2rem;
  text-align: center;
}

.order__section {
  padding: 0.6rem 1.2em;
  border-radius: 0.8rem;
  margin: 0 0 2rem;
  color: $gray-light;
  background-color: $gray-dark;
}

.order__label {
  text-align: left;

  button {
    text-align: left;
  }
}

.order__value {
  text-align: right;
}

.order__row {
  display: flex;
  justify-content: space-between;
  padding: 0.7rem 0;
  border-bottom: 1px solid $gray-line;
  font-size: $font-size-small;

  &:first-child,
  &.-total {
    // padding: 0.7rem 0 0.8rem;
    border-bottom: 0;

    div {
      @extend %header-text;
      color: $gray-light;
      // color: $purple-text;

      span.-lower {
        @extend %body-font;
        text-transform: lowercase;
        color: $gray-light;
      }
    }
  }

  &.-parent {
    border-bottom: 0;
  }

  &.-parent + &.-parent,
  &.-child + &.-parent {
    border-top: 1px solid $gray-line;
  }

  &.-child {
    padding: 0 0 0.7rem;
    border-bottom: 0;
  }

  &:last-child {
    border-bottom: 0;
  }

  // .btn-link {
  //   color: lighten($color-link, 5%);
  //   &:hover,
  //   &:active,
  //   &:focus {
  //     color: $color-link;
  //   }
  // }

  label {
    margin: 0;

    select {
      @extend %filter-styles;
      width: 10rem;
      padding: 0.7rem 1.1rem 0.5rem;
      font-size: 1rem;
      border: 0;
      background-color: $gray-line;
      // background-color: $color-bg-sidebar;
      color: $gray-light;
    }

    .select__arrow {
      right: 0.8rem;
      bottom: 0.8rem;
      @include transform(0.7, 45deg);
      border-color: $gray-light;
    }
  }
}

.order__remove {
  display: inline-block;
  position: relative;
  top: 0.3rem;
  margin: 0 0 0 0.7rem;

  .btn-link {
    color: $color-error;
    &:hover,
    &:active,
    &:focus {
      color: $color-white;
    }
  }
}

.order__cart {
  .order__label button {
    max-width: 24rem;
  }
}

// .order__item__name {
//   width: 40%;

//   .order__row.-child & {
//     padding-left: 2rem;
//   }
// }

// .order__item__price,
// .order__item__quantity {
//   width: 20%;
//   text-align: right;
// }

.checkout {
  display: flex;
  justify-content: space-between;
  @include breakpoint(mobile) {
    flex-direction: column;
  }
}

.checkout__main {
  position: relative;
  width: 65%;
  max-width: 60rem;
  padding: 0 $padding-x $padding-x 0;
  @include breakpoint(tablet_down) {
    width: 60%;
    padding: 0 $padding-x-mobile $padding-x-mobile 0;
  }
  @include breakpoint(mobile) {
    width: 100%;
    padding: 0;
  }
}

.checkout__sidebar {
  width: 35%;
  flex-grow: 2;
  @include breakpoint(tablet_down) {
    width: 40%;
  }
  @include breakpoint(mobile) {
    width: 100%;
  }
}

.checkout__charges,
.checkout__tenders {
  margin: 0 0 4rem;

  .btn-list button {
    padding: 1.2rem 0.6rem 1.1rem;
  }
}

.checkout__form {
  @include clearfix;
  margin: 0 0 3.5rem;

  label {
    margin-bottom: 1.5rem;
    &:last-child {
      margin-bottom: 0;
    }

    span {
      font-size: 1.3rem;
    }

    input[type='text'],
    textarea {
      font-size: 1.2rem;
    }

    textarea {
      height: 9.2rem;
    }
  }
}
