/* =============================================================
   Receipt
   ============================================================= */

.receipt {
  position: relative;
  &.-updating {
    .receipt__section {
      opacity: 0.25;
    }
  }

  .sticky {
    margin: -2rem 0 1rem;
  }

  .sticky__inner {
    font-size: $font-size-small;
  }

  .sticky.-stuck .sticky__inner {
    @include breakpoint(mobile_both) {
      left: 0;
      padding: 0 $padding-x-mobile 1rem;
    }
  }
}

.receipt-stars {
  display: inline-block;
  span {
    display: inline-block;
    width: 1.8rem;
    height: 1.8rem;
    margin-left: 0.5rem;
  }
}

.receipt__updating {
  position: absolute;
  z-index: 1;
  top: 0;
  left: 0;
  width: 100%;
  padding-top: 15rem;
  text-align: center;
}

.receipt__updating__loader {
  display: inline-block;
  span {
    display: inline-block;
    margin-top: 0.5rem;
    font-size: $font-size-small;
  }
}

.receipt__section {
  padding: 1rem 1.5em;
  border-radius: 0.8rem;
  margin: 0 0 $padding-x;
  background-color: $purple-light;
  @include breakpoint(mobile_both) {
    margin: 0 0 $padding-x-mobile;
  }
}

.receipt__label {
  text-align: left;

  .receipt__row.-child & {
    padding-left: 2rem;
  }
}

.receipt__remove {
  display: inline-block;
  position: relative;
  top: 0.3rem;
  margin: 0 0 0 0.7rem;

  // .btn-link {
  //   color: $color-error;
  //   &:hover,
  //   &:active,
  //   &:focus {
  //     color: $color-body;
  //   }
  // }
}

.receipt__value {
  padding-left: 1rem;
  text-align: right;
  // @extend %ellipsis;

  .-open &,
  .-authorized &,
  .-none & {
    // font-weight: bold;
    text-transform: uppercase;
    color: $color-body-2nd;
  }

  .-held & {
    font-weight: bold;
    text-transform: uppercase;
    color: $color-link;
  }

  .-closed &,
  .-paid &,
  .-sale & {
    font-weight: bold;
    text-transform: uppercase;
    // color: $color-success;
  }

  .-void &,
  .-refunded &,
  .-refund & {
    font-weight: bold;
    text-transform: uppercase;
    color: $color-error;
  }
}

.receipt__row {
  // position: relative;
  display: flex;
  justify-content: space-between;
  padding: 0.7rem 0 0.8rem;
  border-bottom: 1px solid $purple-line;
  font-size: $font-size-small;
  // @extend %mono-font;
  // font-size: 1.2rem;

  &:first-child,
  &.-totals {
    // padding: 0.7rem 0 0.8rem;
    border-bottom: 0;

    div {
      @extend %header-text;
    }
  }

  &:last-child {
    border-bottom: 0;
  }

  &.-subtotals {
    font-weight: bold;
  }

  // &.-totals {
  //   border-top: 1px solid $purple-line;
  // }

  &.-parent {
    border-bottom: 0;
    padding-bottom: 0.6rem;
  }

  // &.-parent + &.-parent,
  // &.-child + &.-parent {
  //   border-top: 1px solid $purple-line;
  // }

  &.-child {
    padding: 0 0 0.8rem;
    border-bottom: 0;
    font-size: 1.1rem;
    // color: $purple-pale;
  }

  &.-child.-last {
    padding-bottom: 1.1rem;
    border-bottom: 1px solid $purple-line;
  }

  &.-disabled {
    color: $purple-pale;
  }

  &.-indent {
    padding: 0.7rem 0 0;
    border-bottom: 0;
    font-size: 1.1rem;
    color: $purple-pale;

    // .receipt__label {
    //   padding-left: 1rem;
    // }
  }

  &.-full-width {
    display: block;
    .receipt__label,
    .receipt__value {
      width: 100%;
      text-align: left;
      padding: 0;
    }

    .receipt__label {
      font-weight: bold;
    }
  }

  &.-full-mobile {
    @include breakpoint(mobile_both) {
      flex-direction: column;
    }
    .receipt__label,
    .receipt__value {
      padding: 0;
      text-align: left;
    }
  }
}

.receipt__spacer {
  padding-bottom: 1.2rem;

  & + .receipt__spacer {
    padding-bottom: 0;
    border-top: 0.4rem double $purple-line;
  }
}

// .receipt__item {
//   border-bottom: 1px solid $purple-line;
//   .receipt__row {
//     border-bottom: 0;
//   }
// }

// #items {
//   .receipt__row.-child {
//     color: $color-body;
//   }
// }

.receipt__item__name {
  position: relative;
  width: 40%;

  .receipt__row.-child & {
    padding-left: 1.5rem;
  }
}

.receipt__item__price,
.receipt__item__quantity {
  width: 20%;
  text-align: right;
}

.receipt__item__name__quantity {
  position: absolute;
  top: 0;
  left: -4.5rem;
  width: 3.5rem;
  text-align: right;

  .receipt__row.-child & {
    display: none;
  }
}
