.dz-wrapper {
  margin-bottom: 3rem;
}

.dz-wrapper__url {
  font-size: $font-size-small;
  margin: 1rem 0;
  @extend %ellipsis;
}

.dz-dropzone {
  position: relative;
  width: 100%;
  min-height: 20rem;
  background-color: $purple-light;
  border-radius: 5px;
  font-size: $font-size;
  color: $purple;
  @include transition();
  @include breakpoint(mobile_both) {
    min-height: 12rem;
  }
}

.dz-file-input {
  display: none;
}

.dz-upload {
  position: absolute;
  z-index: 1;
  width: 100%;
  height: 100%;
  border-radius: 5px;
  display: flex;
  align-items: center;
  justify-content: center;
  flex-direction: column;
  @include transition();

  .has-file &,
  .processing & {
    opacity: 0;
    visibility: hidden;
  }
}

.dz-icon {
  color: $purple;
  width: 5.2rem;
  @include breakpoint(mobile_both) {
    margin-top: 1.5rem;
    width: 3.6rem;
  }
}

.dz-icon-msg {
  display: inline-block;
  padding: 0 3rem;
  text-align: center;
  @include breakpoint(mobile) {
    line-height: 1.2;
    font-size: $font-size-small;
  }
}

.dz-filetype {
  position: absolute;
  z-index: 2;
  top: 0.8rem;
  left: 1.6rem;
  font-weight: bold;
  text-transform: uppercase;
  font-size: $font-size-small;
  letter-spacing: 0.08em;
  @include transition();

  .has-file & {
    color: $color-white;
    // text-shadow: 0 0 10px rgba(0, 0, 0, 0.75);
  }
}

.dz-dropzone:hover,
.dz-highlight {
  .dz-upload {
    background-color: $purple;
  }

  &.has-file:not(.processing) .dz-upload {
    opacity: 1;
    visibility: visible;
    background-color: rgba($purple, 0.5);
  }

  .dz-icon,
  span {
    color: $color-white;
  }

  .dz-filetype {
    color: $color-white;
    text-shadow: none;
  }
}

.dz-image {
  display: block;
  border-radius: 5px;
}

.dz-delete-text {
  // opacity: 0;
  // visibility: hidden;
  // max-width: 0;
  color: $color-white;
  padding-right: 0.8rem;
  @include transition();
}

.dz-delete-icon {
  margin-top: 0.1rem;
  @include transition();
}

.dz-delete {
  position: absolute;
  z-index: 2;
  top: 1rem;
  right: 1rem;
  // width: 40px;
  height: 4rem;
  padding: 0 2rem;
  display: flex;
  align-items: center;
  justify-content: center;
  background-color: rgba($color-black, 0.6);
  opacity: 0;
  visibility: hidden;

  &:hover,
  &:active,
  &:focus {
    opacity: 1;
    visibility: visible;
    background-color: rgba($red, 1);
  }
}

.dz-upload:hover ~ .dz-delete,
.dz-image:hover ~ .dz-delete {
  opacity: 1;
  visibility: visible;
}

.dz-progress {
  position: absolute;
  z-index: 3;
  width: 0;
  height: 100%;
  display: flex;
  align-items: center;
  justify-content: center;
  flex-direction: column;
  background-color: $purple;
  border-radius: 5px;
  @include transition();

  .has-file & {
    background-color: rgba($purple, 0.5);
  }

  span {
    opacity: 0;
    visibility: hidden;
    color: $color-white;
    @include transition();
  }

  .processing & {
    span {
      opacity: 1;
      visibility: visible;
    }
  }
}

.dz-filename {
  position: absolute;
  z-index: 1;
  width: 100%;
  height: 100%;
  border-radius: 5px;
  display: flex;
  align-items: center;
  flex-direction: row;
  justify-content: left;

  .dz-icon {
    width: 4rem;
    padding: 0.6rem 0.8rem 0 0;
    text-align: right;
  }

  span:not(.dz-icon) {
    line-height: 1;
    padding-bottom: 0.2rem;
  }
}

.dz-dropzone.dz-pdf,
.dz-dropzone.dz-raw-image {
  min-height: 4rem;
  @include breakpoint(mobile_both) {
    min-height: 6rem;
  }

  .dz-upload {
    flex-direction: row;
    justify-content: left;

    .dz-icon {
      width: 4rem;
      min-width: 4rem;
      padding: 0.7rem 0.8rem 0 0;
      text-align: right;
      @include breakpoint(mobile_both) {
        min-width: 4.8rem;
        padding-right: 1.4rem;
        margin-top: 0;
      }
    }

    span:not(.dz-icon) {
      line-height: 1;
      padding: 0 1.5rem 0.2rem 0;
      @include breakpoint(mobile) {
        line-height: 1.2;
        font-size: $font-size-small;
      }
    }
  }

  .dz-progress {
    flex-direction: row;
    justify-content: left;
    background-color: $purple;

    .dz-processing-icon {
      width: 4rem;
      padding: 0.4rem 0.8rem 0 0;
      text-align: right;
    }

    span:not(.dz-icon) {
      line-height: 1;
      padding-bottom: 0.2rem;
    }
  }

  .dz-delete {
    top: 0;
    height: 100%;
    padding: 0;
    background-color: transparent;

    &:hover,
    &:active,
    &:focus {
      .dz-delete-text,
      .dz-delete-icon {
        color: $red;
      }
    }
  }

  &.has-file:hover .dz-upload {
    background-color: $purple;
  }
}

.dz-logo .dz-dropzone {
  min-height: 10rem;
}

.dz-marker .dz-dropzone {
  min-height: 5rem;
  max-height: 5rem;
  text-align: right;

  .dz-filetype {
    color: $color-body;
  }

  .dz-icon {
    width: 2.6rem;
    margin: 1rem 0 0;
  }

  .dz-icon-msg {
    display: none;
  }

  .dz-image {
    float: right;
    width: auto;
    height: 5rem;
  }
}
