/* =============================================================
   Animations & Transitions
   ============================================================= */

// slide in / out

$slide-speed: 250ms;

.slide-enter {
  opacity: 0;
  @include translate3d(100%, 0, 0);
}

.slide-enter.slide-enter-active {
  opacity: 1;
  @include translate3d(0, 0, 0);
  transition: all $slide-speed ease;
}

.slide-exit {
  opacity: 1;
  @include translate3d(0, 0, 0);
}

.slide-exit.slide-exit-active {
  opacity: 0;
  @include translate3d(100%, 0, 0);
  transition: all $slide-speed ease;
}

// fade in / out

$fade-speed: 250ms;

.fade-enter {
  opacity: 0;
}

.fade-enter.fade-enter-active {
  opacity: 1;
  transition: opacity $fade-speed ease-in;
}

.fade-exit {
  opacity: 0;
  transition: opacity $fade-speed ease-out;
}

.fade-exit.fade-ext-active {
  opacity: 1;
}

// fade in & up / out & down

$fade-up-speed: 500ms;

.fade-up-enter {
  opacity: 0;
  @include translate3d(0, 2%, 0);
}

.fade-up-enter.fade-up-enter-active {
  opacity: 1;
  @include translate3d(0, 0, 0);
  transition: all $fade-up-speed ease;
}

.fade-up-exit {
  opacity: 1;
  @include translate3d(0, 0, 0);
}

.fade-up-exit.fade-up-exit-active {
  opacity: 0;
  @include translate3d(0, 2%, 0);
  transition: all $fade-up-speed ease;
}

.spin {
  animation: Spin infinite 20s linear;
}

@keyframes Spin {
  from {
    transform: rotate(0deg);
  }
  to {
    transform: rotate(360deg);
  }
}
