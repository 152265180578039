.discount-items {
  &__content {
    @include clearfix;
    width: 100%;
  }

  &__left,
  &__right {
    float: left;
    width: 47.5%;
    margin-right: 5%;
    @include breakpoint(mobile) {
      width: 100%;
      margin: 0;
    }
  }

  &__right {
    margin: 0;
  }
}
