/* =============================================================
   Sidebar
   ============================================================= */

%sidebar-transition {
  @include transition(all, 200ms, ease-in-out);
}

.nav {
  position: fixed;
  top: 0;
  bottom: 0;
  // padding-top: $header-height;

  .btn {
    padding: 0.7rem 1.5rem 0.9rem;
    border: 1px solid $color-white;
    &:hover,
    &:focus,
    &:active {
      border: 1px solid $color-body;
      background-color: $color-body;
    }
    &:disabled {
      &:hover,
      &:focus,
      &:active {
        border: 1px solid $color-white;
        background-color: $color-link;
      }
    }
  }
}

.nav__cover {
  position: absolute;
  z-index: 1;
  width: 100%;
  height: 100%;
  background-color: rgba($color-link, 0.75);
  opacity: 0;
  visibility: hidden;
  @extend %sidebar-transition;

  .siblings & {
    background-color: rgba($color-body, 0.75);
  }
}

.nav__close {
  position: absolute;
  z-index: 1;
  top: 0.5rem;
  right: 0.3rem;
  color: $color-white;
  @include breakpoint(mobile_both) {
    top: 0.8rem;
    right: 0.8rem;
  }

  .btn-link {
    width: 1.6rem;
    height: 1.6rem;
    color: $color-white;
    &:hover,
    &:active,
    &:focus {
      color: $color-link;
    }
    @include breakpoint(mobile_both) {
      width: 2rem;
      height: 2rem;
    }
  }
}

.sidebar__loader {
  padding: 2rem 2rem 0;
}

$mobile-tabs-width: 50%;

.tabs {
  z-index: 11;
  left: $sidebar-width + $main-item-width;
  right: 0;
  color: $color-sidebar;
  background-color: $color-link;
  @extend %sidebar-transition;

  @media only screen and (min-width: $bp-tablet-land + 1px) and (max-width: 1360px) {
    left: auto;
    right: $sidebar-width;
    width: $sidebar-width;
  }

  @include breakpoint(tablet_both) {
    left: auto;
    right: $sidebar-width;
    width: $sidebar-width;
  }

  @include breakpoint(mobile_land) {
    left: auto;
    right: -$sidebar-width;
    width: $sidebar-width;
  }

  @include breakpoint(mobile) {
    left: auto;
    right: -$mobile-tabs-width;
    width: $mobile-tabs-width;
  }

  .-open & {
    @include breakpoint(mobile_land) {
      @include translate3d(-($sidebar-width * 2), 0, 0);
    }
    @include breakpoint(mobile) {
      @include translate3d(-(4 * $mobile-tabs-width), 0, 0);
    }
  }

  .app--nav & {
    left: auto;
    right: -$sidebar-width;
    width: $sidebar-width;
    @include breakpoint(mobile) {
      right: -$mobile-tabs-width;
      width: $mobile-tabs-width;
    }
  }

  .app--nav.-open & {
    @include translate3d(-($sidebar-width * 2), 0, 0);
    @include breakpoint(mobile) {
      @include translate3d(-(4 * $mobile-tabs-width), 0, 0);
    }
  }

  .app--content & {
    left: $sidebar-width + $main-content-width;
  }
}

.siblings {
  z-index: 12;
  left: $sidebar-width * 2 + $main-item-width;
  right: 0;
  color: $color-sidebar;
  background-color: $color-bg-sidebar;
  @extend %sidebar-transition;

  @media only screen and (min-width: $bp-tablet-land + 1px) and (max-width: 1360px) {
    left: auto;
    right: 0;
    width: $sidebar-width;
  }

  @include breakpoint(tablet_both) {
    left: auto;
    right: 0;
    width: $sidebar-width;
  }

  @include breakpoint(mobile_land) {
    left: auto;
    right: -(2 * $sidebar-width);
    width: $sidebar-width;
  }

  @include breakpoint(mobile) {
    left: auto;
    right: -(2 * $mobile-tabs-width);
    width: $mobile-tabs-width;
  }

  .-open & {
    @include breakpoint(mobile_land) {
      @include translate3d(-($sidebar-width * 2), 0, 0);
    }
    @include breakpoint(mobile) {
      @include translate3d(-(4 * $mobile-tabs-width), 0, 0);
    }
  }

  .app--nav & {
    left: auto;
    right: -(2 * $sidebar-width);
    width: $sidebar-width;
    @include breakpoint(mobile) {
      right: -(2 * $mobile-tabs-width);
      width: $mobile-tabs-width;
    }
  }

  .app--nav.-open & {
    @include translate3d(-($sidebar-width * 2), 0, 0);
    @include breakpoint(mobile) {
      @include translate3d(-(4 * $mobile-tabs-width), 0, 0);
    }
  }

  // .app--nav & {
  //   left: auto;
  //   right: -($sidebar-width * 3/4);
  //   width: $sidebar-width;

  //   .nav__cover {
  //     opacity: 1;
  //     visibility: visible;
  //   }

  //   &:hover {
  //     @include translate3d(-($sidebar-width * 3/4), 0, 0);

  //     .nav__cover {
  //       opacity: 0;
  //       visibility: hidden;
  //     }

  //     & ~ .tabs {
  //       @include translate3d(-($sidebar-width * 3/4), 0, 0);
  //     }
  //   }
  // }

  .app--content & {
    left: $sidebar-width * 2 + $main-content-width;
  }

  .nav__nav li {
    a {
      float: left;
      display: block;
      @extend %ellipsis;
    }
  }
}

.nav__container {
  display: flex;
  height: 100%;
  flex-direction: column;
  justify-content: flex-start;
  padding-top: $header-height;
  @include breakpoint(tablet_down) {
    padding-top: $header-height + $nav-title-mobile-padding-top;
  }
}

.sidebar__title,
.nav__title {
  .container {
    height: $header-height;
    display: flex;
    align-items: center;
  }

  p {
    position: relative;
    font-size: 1.8rem;
    // padding: 0 0 0.5rem;

    a {
      color: $color-white;
      &:hover,
      &:active,
      &:focus {
        color: $color-white;
      }
    }
  }
}

.nav__btn {
  padding: 0 2rem 2rem;

  .btn {
    font-size: $font-size;
  }
}

.nav__title {
  position: absolute;
  top: 0;
  width: 100%;
  height: $header-height;
  padding: 0 2rem;
  line-height: 1;
  @include breakpoint(tablet_down) {
    height: $header-height + $nav-title-mobile-padding-top;
    padding-top: $nav-title-mobile-padding-top;
  }

  label {
    width: 100%;
    max-width: $sidebar-width - 4rem;
    margin-bottom: 0;
  }

  input[type='text'],
  input[type='email'],
  input[type='password'],
  input[type='number'],
  input[type='search'],
  input[type='tel'],
  input[type='url'],
  input[type='color'] {
    outline: 0;
    padding: 0.4rem 1.5rem 0.6rem;
    border-radius: 1.5rem;
    @extend %body-font;
    font-size: 1.3rem;
    line-height: 1;
    // padding: 0.9rem 1.3rem 1rem;
    // border-radius: 3rem;
    // @extend %mono-font;

    color: $color-white;
    background-color: transparent;
    border-color: $gray;
    @include placeholder($gray);
    // @include placeholder(rgba($color-white, 1));

    &:focus {
      color: $color-white;
      background-color: transparent;
      border-color: $gray;
    }
  }

  .search__clear {
    margin-top: -0.8rem;
    color: $color-white;
    &:hover,
    &:active,
    &:focus {
      color: $color-link-hover;
    }
  }
}

.nav__search {
  // display: inline-block;
  padding: 0 2rem;

  .nav__search__error {
    @extend .error;
    float: left;
    width: auto;
    // padding: 0.8rem 1.3rem 1rem;
    padding: 0.5rem 0.5rem 0;
    border-radius: 0;
    // @extend %body-font;
    font-weight: 500;
    font-size: 1.3rem;
    line-height: 1;
    border: 0;
    background-color: transparent;
    color: $color-error;
  }
}

.nav__nav {
  padding: 0 0 3rem;
  max-height: 100%;
  overflow-y: scroll;
}

.nav__actions {
  width: $sidebar-width;
  padding: 0 2rem;

  ul li {
    margin-bottom: 1rem;
  }
}

.sidebar__nav,
// .sidebar__footer,
.nav__nav {
  ul li {
    font-size: $font-size;

    &.nav__break {
      margin-top: 1.8rem;
    }

    a {
      display: inline-block;
      line-height: 1;
      width: 100%;
      padding: 0.7rem 2rem;
      color: $color-sidebar;
      // text-align: left;
      // overflow: hidden;
      // text-overflow: ellipsis;
      // white-space: nowrap;

      &:hover,
      &:active,
      &:focus {
        color: $color-sidebar-active;
      }

      &.active {
        color: $color-sidebar-active;
      }

      span.nav__section {
        display: inline-block;
        position: relative;
      }

      span.nav__icon {
        position: absolute;
        top: 1px;
        left: 0;
        width: 1.4rem;
        height: 1.4rem;
      }

      span.nav__name {
        padding: 0 0 0 2.3rem;
      }
    }

    ul {
      display: none;
      padding: 0 0 0.5rem 2.3rem;
    }

    a.active + ul {
      display: block;
    }

    ul li {
      font-size: $font-size-x-small;
      text-transform: uppercase;
      letter-spacing: 0.1em;

      a {
        padding: 0.6rem 2rem 0.6rem 2.1rem;
      }
    }
  }

  p {
    padding: 0.7rem 2rem;
  }
}

.nav__search__nav {
  ul li {
    font-size: $font-size;

    a,
    .btn-link {
      display: inline-block;
      line-height: 1;
      width: 100%;
      padding: 0.7rem 0;
      color: $color-sidebar;
      text-align: left;
      overflow: hidden;
      text-overflow: ellipsis;
      white-space: nowrap;

      &:hover,
      &:active,
      &:focus {
        color: $color-sidebar-active;
      }

      &.active {
        color: $color-sidebar-active;
      }
    }
  }
}

.tabs {
  .nav__title {
    width: $sidebar-width;
    padding: 0 2rem;

    @include breakpoint(tablet_down) {
      height: $header-height + $nav-title-mobile-padding-top;
      padding-top: $nav-title-mobile-padding-top;
    }

    @include breakpoint(mobile) {
      width: 100%;
    }

    .btn {
      padding-top: 0.6rem;
      padding-bottom: 0.8rem;
    }
  }
  .nav__nav {
    // padding-top: $header-height;

    ul li a {
      &:hover,
      &:active,
      &:focus,
      &.active {
        color: $color-white;
        opacity: 0.5;
      }
    }

    ul li span {
      display: inline-block;
      line-height: 1;
      width: 100%;
      padding: 0.7rem 2rem;
      color: $color-white;
      opacity: 0.5;
    }
  }
}

.sidebar {
  position: fixed;
  z-index: 1;
  top: 0;
  bottom: 0;
  left: 0;
  width: $sidebar-width;
  // padding-bottom: $sidebar-footer-height;
  overflow-y: scroll;
  color: $color-sidebar;
  background-color: $color-bg-sidebar;
  // backface-visibility: hidden;
  // -webkit-backface-visibility: hidden;
  // display: flex;
  // flex-direction: column;
  // justify-content: space-between;
}

.sidebar__container {
  display: flex;
  flex-direction: column;
  justify-content: space-between;
  height: 100%;
}

.sidebar__title {
  width: 100%;
  height: $header-height;
  padding: 0 0 0 2rem;
  line-height: 1;

  p {
    padding-left: 2.3rem;
  }
}

.sidebar__brand-icon {
  position: absolute;
  top: 0;
  left: 0;
  width: 14px;
  height: 14px;
  // gradient square
  // top: 2px;
  // @include linear-gradient(90deg, $blue, 0%, $purple, 100%);
  // border-radius: 2px;
}

.sidebar__nav {
  flex-grow: 1;
  padding-bottom: 3rem;
}

.sidebar__footer {
  width: $sidebar-width;
  height: $sidebar-footer-height;
  padding: 0 2rem;

  .container {
    display: flex;
    align-items: center;
    height: $sidebar-footer-height;
  }
}

.sidebar__user {
  ul {
    margin-top: -0.2rem;
    li {
      display: inline-block;
      margin: 0 1rem 0 0;
      font-size: $font-size-small;
      color: $color-sidebar;

      a,
      button {
        padding: 0;
        background-color: transparent;
        font-size: $font-size-small;
        color: $color-sidebar;
        &:hover,
        &:active,
        &:focus {
          color: $color-sidebar-active;
          background-color: transparent;
        }

        &.active {
          color: $color-link;
        }
      }
    }

    li.sidebar__version {
      color: $gray;
    }
  }
}

.sidebar__logout {
  display: none;
  width: $icon-size-mobile;
  height: $icon-size-mobile;
  margin-left: -0.3rem;
  @include transform(1, 180deg);

  a {
    color: $color-white;
  }
}

@media only screen and (max-width: $bp-tablet) {
  .sidebar {
    z-index: 12;
    width: $sidebar-width-mobile;
    left: -$sidebar-width-mobile;
    @include translate3d(0, 0, 0);
    @include transition(all, 200ms, ease-in-out);
  }

  .-open-sidebar .sidebar {
    @include translate3d($sidebar-width-mobile, 0, 0);
  }

  .sidebar__nav {
    ul li a {
      margin: 0.2rem 0;
    }
  }
}

// @media only screen and (max-width: $bp-tablet) {
//   .sidebar {
//     // position: absolute;
//     width: $sidebar-width-mobile;
//   }

//   .sidebar__brand-name {
//     display: none;
//   }

//   .sidebar__footer {
//     width: $sidebar-width-mobile;
//     height: $sidebar-footer-height-mobile;
//     padding: 0;

//     .container {
//       justify-content: center;
//       align-items: flex-start;
//       height: $sidebar-footer-height-mobile;
//     }
//   }

//   .sidebar__user {
//     display: none;
//   }

//   .sidebar__logout {
//     display: block;
//   }

//   .sidebar__container {
//     align-items: center;
//   }

//   .sidebar__title {
//     height: $header-height-mobile;
//     padding: 0;

//     .container {
//       height: $header-height-mobile;
//       justify-content: center;
//       align-items: center;

//       p {
//         padding: 0;
//       }
//     }
//   }

//   .sidebar__brand-icon {
//     position: static;
//     top: auto;
//     left: auto;
//     display: block;
//     width: $icon-size-mobile;
//     height: $icon-size-mobile;

//     img {
//       position: relative;
//       top: -0.8rem;
//     }
//   }

//   .sidebar__nav {
//     margin-top: -0.7rem;
//     ul li {
//       a {
//         width: 100%;
//         padding: 0.6rem 0;

//         span.nav__icon {
//           display: block;
//           position: static;
//           top: auto;
//           left: auto;
//           width: $icon-size-mobile;
//           height: $icon-size-mobile;
//         }

//         span.nav__name {
//           display: none;
//         }

//         &.active + ul {
//           display: none;
//         }
//       }
//     }

//     ul li.nav__break {
//       margin-top: 1.6rem;
//     }
//   }
// }

.pop {
  position: fixed;
  top: $header-height + 2rem;
  bottom: $header-height + 2rem;
  right: 2rem;
  width: $pop-width;
  padding: $header-height-pop 0 0;
  border-radius: 10px;
  background-color: $color-bg-pop;
  color: $color-body;
  @include transition(all, $fade-up-speed, ease);
  @include translate3d(0, 0, 0);

  &.slide-enter,
  &.slide-exit.slide-exit-active {
    opacity: 0;
    @include translate3d(100%, 0, 0);
  }

  &.slide-enter.slide-enter-active,
  &.slide-exit {
    opacity: 1;
    @include translate3d(0, 0, 0);
  }
}

.pop__container {
  display: flex;
  height: 100%;
  flex-direction: column;
  justify-content: flex-start;
}

.pop__title {
  position: absolute;
  top: 0;
  height: $header-height-pop;
  padding: 0 2rem;

  .container {
    height: $header-height-pop;
    display: flex;
    align-items: flex-end;
  }

  p {
    font-size: $font-size-big;
    padding-top: 0.4rem;
  }
}

.pop__nav {
  padding: 0 0 1rem;
  max-height: 100%;
  overflow-y: scroll;

  ul li {
    font-size: $font-size-x-small;

    a {
      display: inline-block;
      width: 100%;
      padding: 0.25rem 2rem;
      color: $color-link;
      &:hover,
      &:active,
      &:focus {
        color: $color-body;
      }

      &.active {
        color: $color-body;
      }
    }
  }
}
