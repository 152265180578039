/* =============================================================
   Menu
   ============================================================= */

.menu {
  margin-top: 2rem;
}

.menu__category {
  padding-top: $header-height * 2;
  margin-top: -$header-height;
  // margin-bottom: 5rem;

  &.-steps {
    padding-top: 0;
    margin-top: $padding-x;
    @include breakpoint(tablet_down) {
      margin-top: $padding-x-mobile;
    }
  }

  &.-child {
    padding-top: $header-height - 2rem;
    margin-top: 0;
  }
}

.menu__category__title {
  margin: 0 0 0.5rem -0.1rem;
}

h2.menu__category__title {
  font-size: 175%;
}

h3.menu__category__title {
  font-size: 150%;
}

.menu__items {
  margin: 0 (-$padding-x) 0 0;
  display: flex;
  flex-wrap: wrap;
  // justify-content: space-between;
  @include breakpoint(tablet_land_down) {
    margin: 0 (-$padding-x-mobile) 0 0;
  }
  @include breakpoint(mobile) {
    margin: 0;
  }

  .app--order & {
    margin: 0 (-$padding-x-mobile) 0 0;
    @include breakpoint(mobile) {
      margin: 0;
    }
  }
}

.is-empty {
  font-style: italic;
}

.menu__item {
  width: 20%;
  padding: 2.5rem $padding-x 0 0;
  @include breakpoint(tablet_land) {
    width: 25%;
    padding-right: $padding-x-mobile;
  }
  @include breakpoint(tablet) {
    width: 25%;
    padding-right: $padding-x-mobile;
  }
  @include breakpoint(mobile_land) {
    width: 33.33333%;
    padding-right: $padding-x-mobile;
  }
  @include breakpoint(mobile) {
    width: 100%;
    padding: $padding-x-mobile 0 0 0;
  }

  .app--order & {
    padding-right: $padding-x-mobile;
    @include breakpoint(mobile) {
      padding-right: 0;
    }
  }

  button {
    width: 100%;
    text-align: left;
    padding: 0;
    border: 0;
    background-color: transparent;
    @extend %body-font;
    cursor: pointer;
    opacity: 1;
    @include transition(all, 0.15s, ease);

    &:hover,
    &:active,
    &:focus {
      // background-color: $purple-light;
      opacity: 0.8;
    }
  }
}

.menu__item__image {
  width: 100%;
  padding: 30%;
  margin-bottom: 1rem;
  @extend %bg-img;
  background-color: $purple-light;
}

.menu__item__name {
  font-size: $font-size-big;
  line-height: 1.3;
  // font-weight: 400;
  // margin: 0 0 0.5rem;

  .app--order & {
    font-size: $font-size;
  }
}

.menu__item__desc {
  margin: 0.4rem 0 0;
  font-size: $font-size-small;

  .app--order & {
    display: none;
  }
}

.menu__item__price {
  margin: 0.4rem 0 0;
  font-size: $font-size;
  font-weight: 400;

  .app--order & {
    font-size: $font-size-small;
  }
}

.menu__item__id {
  margin: 0 0 0.4rem;
  font-size: $font-size-x-small;
}
