/* =============================================================
   Charts
   ============================================================= */

.reports__section {
  @include clearfix;
  width: 100%;
}

.reports__loading {
  margin-top: 5rem;
  text-align: center;

  & > div:first-of-type {
    display: inline-block;
  }

  p {
    margin: 1rem 0 0;
    font-size: $font-size-small;
  }
}

.report__dates {
  .btn {
    @include breakpoint(mobile) {
      width: 30%;
      margin: 0 4.5% 1rem 0;
      padding-left: 0;
      padding-right: 0;
    }

    &:nth-child(3n) {
      @include breakpoint(mobile) {
        margin-right: 0;
      }
    }

    @extend %filter-styles;
    background-color: $purple-light;
    border-color: $purple-light;
    color: $color-link;

    &.-active {
      background-color: $color-link;
      border-color: $color-link;
      color: $color-white;
    }
  }
}

.report__actions {
  width: 100%;
  padding: 0;
  margin: 3rem 0 0;
  @include breakpoint(mobile) {
    margin-top: 1rem;
  }

  .toggle-group {
    .btn {
      &:first-child {
        padding-right: 2.2rem;
        border-top-left-radius: 2.4rem;
        border-bottom-left-radius: 2.4rem;
      }

      &:last-child {
        padding-left: 1.8rem;
        border-top-right-radius: 2.4rem;
        border-bottom-right-radius: 2.4rem;
      }
    }
  }
}

.report__actions__container {
  display: flex;
  justify-content: space-between;
  align-items: center;
  @include breakpoint(mobile_both) {
    flex-direction: column;
    align-items: start;
  }
}

.report__actions__right {
  @include breakpoint(mobile_both) {
    margin-top: 1rem;
  }
  .toggle-group,
  button {
    margin: 0 0 0 2rem;
    @include breakpoint(mobile) {
      margin: 1rem 1rem 0 0;
      font-size: $font-size-small;
    }
    &:first-child {
      margin: 0;
      @include breakpoint(mobile) {
        margin-top: 0;
      }
    }
  }
}

.report__results {
  display: inline-block;

  p {
    display: inline-block;
    // color: $gray-light;
    font-size: $font-size-small;
    @include breakpoint(mobile_both) {
      display: inline;
    }
  }
}

.report__results__refresh {
  @extend .table__results__refresh;
}

.report__table {
  @include clearfix;
  position: relative;
  width: 100%;
  padding: 0;
  margin: 3rem 0 0;
  @include breakpoint(mobile_both) {
    overflow-x: scroll;
  }

  &.-overflow {
    overflow-x: scroll;
  }
}

.reports {
  @include clearfix;
  margin: 3rem -2rem 0;

  text,
  span,
  tspan {
    fill: $color-body;
    // font-size: $font-size-small;
    font-size: 1.1rem;
  }

  .recharts-label {
    tspan {
      fill: $color-white;
    }
  }
}

.report {
  float: left;
  width: 50%;
  padding: 0 2rem;
  margin: 0 0 4rem;
  @include breakpoint(tablet_down) {
    width: 100%;
  }

  &.-full {
    width: 100%;
  }
}

.report__empty {
  display: flex;
  justify-content: center;
  align-items: center;
  background-color: $purple-light;
  // background-color: $purple-lighter;
}

.report__header {
  padding: 1.2rem 1rem 1.2rem;
  margin-bottom: 1.5rem;
  background-color: $purple-light;

  @include breakpoint(mobile_both) {
    padding: 1.2rem 1rem 1.2rem;
  }

  h2 {
    @extend %header-text;
    margin-left: 0;
    font-size: $font-size;
    @include breakpoint(mobile_both) {
      font-size: $font-size-small;
    }
    // font-size: 125%;
    // margin-left: 0;
  }

  & + div .table__wrapper {
    margin-top: 3rem;
  }
}

.report--throughput {
  width: 100%;

  table thead tr th,
  table tbody tr td {
    &:nth-child(n + 2) {
      text-align: right;
    }
  }
}

.report--item-counts {
  width: 100%;

  table thead tr th,
  table tbody tr td {
    &:nth-child(n + 2) {
      text-align: right;
    }
    &:nth-child(even):not(:nth-child(2)) {
      text-align: left;
      padding-left: 2rem;
    }
  }
}

.report--order-prep {
  width: 100%;

  table thead tr th,
  table tbody tr td {
    &:nth-child(n + 2) {
      text-align: right;
    }
    &:nth-child(odd):not(:nth-child(1)) {
      width: 12%;
    }
    &:nth-child(even):not(:nth-child(2)) {
      text-align: left;
      padding-left: 2rem;
      width: 6%;
    }
    &:nth-child(2) {
      width: 6%;
    }
    &:nth-child(1) {
      width: 22%;
    }
  }
}

.report--totals {
  table tbody tr:last-child {
    background-color: $color-bg-body;
    border-top: 0.1rem solid $purple-light;
    border-bottom: 0.3rem double $purple-light;
    td {
      font-weight: bold;
      text-transform: uppercase;
    }
  }
}

.report--product-mix {
  table thead tr th,
  table tbody tr td {
    width: 40%;
    &:nth-child(n + 2) {
      text-align: right;
      width: 20%;
    }
  }

  .recharts-label {
    tspan {
      fill: $color-body;
    }
  }
}

.report__totals {
  margin: 2rem 0 2rem;
  table tbody tr {
    background-color: $purple-lighter;
    td {
      @extend %header-text;
    }
    // th {
    //   padding: 0.8rem 0.4rem;
    //   border: 0;
    //   line-height: 1.5;
    //   &:first-child {
    //     padding-left: 1rem;
    //   }
    // }
  }
}
