@import './src/sass/_resets';
@import './src/sass/_fonts';
@import './src/sass/_vars';
@import './src/sass/_mixins';
@import './src/sass/_typography';
@import './src/sass/_error';
@import './src/sass/_forms';
@import './src/sass/_quill';
@import './src/sass/_tables';
@import './src/sass/_animation';
@import './src/sass/_landing';
@import './src/sass/_dropzone';
@import './src/sass/_datepicker';
@import './src/sass/_menu';
@import './src/sass/_receipt';
@import './src/sass/_order';
@import './src/sass/_order-mgmt';
@import './src/sass/_mapping';
@import './src/sass/_reports';
@import './src/sass/_request';
@import './src/sass/_discount-items';

@import './src/sass/_header';
@import './src/sass/_message';
@import './src/sass/_sidebar';
@import './src/sass/_aside';
@import './src/sass/_modal';
@import './src/sass/_sections';
@import './src/sass/_pages';
@import './src/sass/_sticky';

body.is-modal {
  height: 100%;
  overflow: hidden;
}

.hidden {
  display: none;
}

.alert-error {
  color: $color-error;
}

.app {
  margin: 0;
  background-color: $color-body;
}

.app__content {
  z-index: 0;
}

.app__background {
  position: fixed;
  z-index: -1;
  width: 80%;
  bottom: 10%;
  right: -3%;
  opacity: 0.03;
}

.back-to-top {
  position: fixed;
  bottom: 1rem;
  left: $main-item-width + $sidebar-width;
  margin-left: -5rem;
  @include breakpoint(tablet_down) {
    left: auto;
    right: 1rem;
  }
}

.main {
  display: flex;
  flex-direction: row;
  min-height: 100vh;
  padding-top: $header-height;
  padding-left: $sidebar-width;
  background-color: $color-bg-body;
  @include breakpoint(tablet_down) {
    padding-left: 0;
    padding-top: $header-height-mobile;
  }
  @include breakpoint(mobile_both) {
    flex-direction: column;
    min-height: 100vh;
    position: relative;
  }

  > div {
    width: 100%;
  }

  .app--content &,
  .app--aside &,
  .app--item & {
    max-width: $main-item-width + $sidebar-width;
    @media only screen and (min-width: $bp-tablet-land + 1px) and (max-width: 1360px) {
      max-width: none;
      padding-right: 2 * $sidebar-width;
    }
    @include breakpoint(tablet_both) {
      max-width: none;
      padding-right: 2 * $sidebar-width;
    }
    @include breakpoint(mobile_both) {
      max-width: none;
      padding-right: 0;
    }
  }

  // .app--content &,
  // .app--aside & {
  //   max-width: $main-content-width + $sidebar-width;
  //   @include breakpoint(tablet) {
  //     max-width: $main-content-width + $sidebar-width-mobile;
  //   }
  //   @include breakpoint(mobile_both) {
  //     max-width: none;
  //   }
  // }
}

.main__overlay {
  position: fixed;
  z-index: 11;
  top: 0;
  bottom: 0;
  left: 0;
  right: 0;
  background-color: rgba($purple, 0.5);
}

.actions {
  position: fixed;
  z-index: 10;
  bottom: 0;
  left: $sidebar-width;
  right: 0;
  height: $footer-height;
  background-color: rgba($color-white, 0.8);
  // border-top: 1px solid $purple-light;
  // vertical-align: bottom;

  .app--item & {
    right: auto;
    width: $main-item-width;
    @include breakpoint(tablet_land) {
      width: auto;
      right: $sidebar-width * 2;
    }
  }

  .container {
    height: $footer-height;
    display: flex;
    align-items: center;
    @extend %padding;
  }

  button,
  .btn {
    margin: -0.1rem 1rem 0 0;
    // box-shadow: 0px 2px 8px 0px rgba($color-black, 0.3);
  }
}

.content {
  width: 100%;
  margin: 0 0 8rem;
  // @extend %padding;
  padding: 0 $padding-x;
  @include breakpoint(mobile_both) {
    padding: 0 $padding-x-mobile;
    margin: 0 0 14rem;
  }
}

.footer {
  display: none;
  position: absolute;
  bottom: 0;
  left: 0;
  right: 0;
  height: 10rem;
  padding: 0 2.4rem 1.8rem;
  background-color: $color-bg-sidebar;
  @include breakpoint(mobile_both) {
    display: flex;
    flex-direction: column;
    justify-content: flex-end;
  }
}

.footer__copyright {
  margin: 1.8rem 0 0;
  p {
    font-size: $font-size-small;
    color: $gray;
  }
}

.back-link {
  display: flex;
  width: 100%;
  margin-left: -0.3rem;
  @include breakpoint(mobile_both) {
    font-size: $font-size-small;
  }

  a {
    display: flex;
    height: 3rem;
    align-items: center;

    span {
      padding: 0 0 0.1rem 0.5rem;
    }
  }
}

.content__header {
  margin: 0.75rem 0 2.5rem;

  // h1 {
  //   line-height: 0.85;
  // }

  p {
    margin-top: 1rem;
    @extend %subheading-font;
    // line-height: 1.5;
  }
}

.content__header__title {
  .back-link {
    margin-bottom: 2rem;
  }
}

.app--content {
  .content {
    h2 {
      margin-left: 0;
      font-size: 2rem;
      // font-weight: bold;

      + p {
        margin-top: 0.75rem;
      }
    }
  }
}

.content__container {
  position: relative;
}

.content__main {
  position: relative;
}

.content__files {
  display: flex;
  justify-content: space-between;
  align-items: flex-start;

  & > div {
    width: 48%;
  }
}

.content--narrow {
  max-width: 600px;
  @include breakpoint(tablet_down) {
    max-width: 100%;
  }
}

.content__main__table {
  width: 100%;
  margin: 5rem 0 5rem;
}

.content__main__table__header {
  margin-bottom: 1.5rem;

  h2 {
    @extend h3;
  }
}

.content__main__content {
  margin: -1rem 0 2.5rem;
}

.content__loading {
  position: absolute;
  top: 15rem;
  left: 0;
  right: 0;
  text-align: center;
}

.content__note {
  margin: 0rem 0 3rem;
  font-weight: 500;
  font-size: $font-size-small;
  text-transform: uppercase;
}

.content__actions {
  .btn {
    margin-left: 1rem;
  }

  .btn:first-child {
    margin-left: 0;
  }
}

.image {
  position: fixed;
  top: 0;
  bottom: 0;
  right: 0;
  left: $main-item-width + $sidebar-width;
  @extend %bg-img;
  background-color: $color-body;
  @include breakpoint(mobile_both) {
    display: none;
  }

  .app--content & {
    left: $main-content-width + $sidebar-width;
    @include breakpoint(tablet_both) {
      left: auto;
      width: 2 * $sidebar-width;
    }
  }
}

/* Pages
   ============================================================= */

.app--error {
  .main {
    padding-top: 3 * $header-height;
    background-color: $color-error-bg;
  }

  h1,
  h2,
  h3,
  h4,
  h5,
  h6,
  p,
  ul li,
  ol li {
    color: $color-error;
  }

  a {
    color: $color-error;
    padding: 0 0 0.2rem;
    border-bottom: 1px solid $color-error;
    &:active,
    &:hover,
    &:focus {
      color: $color-body;
      border-bottom: 1px solid $color-body;
    }
  }
}

.iframe-preview {
  width: 100%;
  height: 100%;
}

.email-preview {
  // padding: 4rem 0;
  margin: 3rem 0;
  font-weight: normal;
  letter-spacing: 0;

  h1 {
    font-size: 2em;
  }
  h2 {
    font-size: 1.5em;
  }
  h3 {
    font-size: 1.17em;
  }
  h4 {
    font-size: 1em;
  }
  h5 {
    font-size: 0.83em;
  }
  h6 {
    font-size: 0.67em;
  }

  table {
    border-collapse: unset;
    border-spacing: unset;

    td {
      padding: 0;
    }
  }
}
